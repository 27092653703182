<div class="login-clean">
  <form
    *ngIf="!isLoggedIn"
    name="form"
    (ngSubmit)="f.form.valid && onSubmit()"
    #f="ngForm"
    novalidate
  >
    <div class="intro small">
      <img
        src="assets/img/logos/customer-logo.png"
        alt=""
        class="logo-login"
      />
      <h3 class="title login pt-2">Anmelden</h3>
      <i class="icon ion-log-in"></i>
    </div>
    <div class="intro large">
      <img
        src="assets/img/logos/customer-logo.png"
        alt=""
        class="logo-login"
      />
      <h3 class="text-center title login pt-2">Anmelden</h3>
      <i class="icon ion-log-in"></i>
    </div>
    <div class="input-container">
      <input
        name="username"
        [(ngModel)]="form.username"
        #username="ngModel"
        type="text"
        id="username"
        required="required"
      />
      <label for="Username">Nutzername</label>
      <div class="bar"></div>
    </div>
    <div *ngIf="f.submitted && username.errors" class="alert alert-danger">
      <div *ngIf="username.errors.required">Nutzername ist erfolderlich!</div>
    </div>
    <div class="input-container">
      <input
        name="password"
        [(ngModel)]="form.password"
        #password="ngModel"
        type="password"
        id="password"
        required="required"
        minlength="4"
      />
      <label for="Username">Passwort</label>
      <div class="bar"></div>
    </div>
    <div
      *ngIf="f.submitted && password.errors"
      class="alert alert-danger"
      role="alert"
    >
      <div *ngIf="password.errors.required">Passwort ist erfolderlich!</div>
      <div *ngIf="password.errors.minlength">
        Passwort muss mindestens 4 Zeichen besitzen!
      </div>
    </div>
    <div class="form-group">
      <button
        [disabled]="loading"
        class="d-block mx-auto px-3 w-100 btn btn-custom"
      >
        <span
          *ngIf="loading"
          class="spinner-border spinner-border-sm mr-1"
        ></span>
        <i *ngIf="!loading" class="fas fa-sign-in-alt mr-1"></i>
        <span class="ml-1">Anmelden</span>
      </button>
      <!--div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div-->
    </div>
    <div class="form-group">
      <div
        class="alert alert-danger"
        role="alert"
        *ngIf="f.submitted && isLoginFailed"
      >
        <span>Login fehlgeschlagen: {{ errorMessage }}</span>
      </div>
    </div>
    <div class="alert alert-success" *ngIf="isLoggedIn">
      Angemeldet als {{ roles }}
    </div>
  </form>
</div>
