import { MatDialogRef } from '@angular/material/dialog';
import { OrderItemProcessEnum } from 'src/app/enums/orderitemprocess.enum';
import { ModalComponent } from 'src/app/modal/modal.component';
import { OrderItem } from 'src/app/screens/order-item-overview/order-item-overview.component';
import { OrderitemService } from 'src/app/services/orderitem.service';
import { Task } from '../task';

export class RunOrderItemUserlessTask implements Task {
  private orderItem: OrderItem | null = null;
  private orderItemService: OrderitemService | null = null;
  infodialogbar: any;

  async execute(dialogRef?: MatDialogRef<ModalComponent>): Promise<void> {
    if (this.orderItem != null) {
      this.orderItemService?.handleOrderItem(
        OrderItemProcessEnum.STARTUSERLESS,
        this.orderItem,
        this.infodialogbar
      );
    }
    dialogRef?.close(true);
    console.log('Executed ' + RunOrderItemUserlessTask.name);
  }

  setValues(
    orderItem: OrderItem,
    orderItemService: OrderitemService,
    infodialogbar: any
  ) {
    this.orderItem = orderItem;
    this.orderItemService = orderItemService;
    this.infodialogbar = infodialogbar;
  }
}
