<div class="features-boxed mb-5 resultdetail">
  <div class="container mt-3">
    <div class="row">
      <div class="col">
        <button
          class="btn btn-custom btn-outline btnBack"
          type="button"
          [routerLink]="['/results']"
        >
          <i class="fa fa-chevron-left"></i>
          <span class="ml-1">Zeiten</span>
        </button>
      </div>
    </div>
  </div>
  <div class="intro small">
    <h3 class="title">Zeitauswertung</h3>
  </div>
  <div class="intro large">
    <h3 class="text-center title">Zeitauswertung</h3>
  </div>
  <div class="container">
    <h4 class="ml-2">Gesamt</h4>
    <div class="orderItemsContainer selfresult">
      <div *ngIf="isAdmin" class="custom-select-box">
        <select class="form-control" (change)="changeUser($event)">
          <optgroup label="Personengruppe...">
            <option value="0" selected>
              {{ user.vorname }} {{ user.name }}
            </option>
            <option value="1">Alle Mitarbeiter</option>
          </optgroup>
        </select>
        <i class="fas fa-caret-down custom-select-box-icon"></i>
      </div>
      <div class="custom-select-box">
        <select
          class="form-control"
          [(ngModel)]="selectedYear"
          (change)="changeYear($event)"
        >
          <option [ngValue]="null" disabled>Jahr wählen...</option>
          <option *ngFor="let year of getYears()" [value]="year.label">
            {{ year.label }}
          </option>
        </select>
        <i class="fas fa-caret-down custom-select-box-icon"></i>
      </div>

      <div *ngFor="let month of monthsList" class="monthitem">
        <div
          class="btn-custom selresultmonth"
          (click)="toggleMonthState(month)"
          [ngClass]="{ open: month.isActive }"
        >
          <span class="ml-2">{{ month.text }}</span>
          <div class="month-pointer">
            <i
              class="fas fa-chevron-right"
              [ngClass]="{ down: month.isActive }"
            ></i>
          </div>
        </div>
        <div *ngIf="month.isActive" class="selresultmonth info">
          <div *ngIf="month.isLoading" class="text-center">
            <div class="spinner-border spinner-border-sm"></div>
          </div>
          <div
            *ngIf="!month.isLoading && selectedUser == '0'"
            class="resultentry"
          >
            <div>Gesamt</div>
            <div>{{ month.summarized }}h</div>
          </div>
          <div
            *ngIf="!month.isLoading && selectedUser == '1'"
            class="resultentry"
          >
            <div>Name</div>
            <div>Zeit1</div>
            <div>Zeit2</div>
          </div>
          <div *ngFor="let orderitem of month.orderitems" class="resultentry">
            <div>{{ orderitem.text }}</div>
            <div>{{ orderitem.time }}h</div>
            <div *ngIf="selectedUser == '1'">{{ orderitem.timeWTZ }}h</div>
          </div>
        </div>
      </div>
    </div>
    <h4 class="ml-2 mt-4">Zeiten</h4>
    <div class="custom-select-box">
      <select class="form-control mb-2" [(ngModel)]="selectedIntervall">
        <option [ngValue]="null" disabled>Zeitraum wählen...</option>
        <option
          *ngFor="let intervall of getIntervalls()"
          [value]="intervall.label"
        >
          {{ intervall.label }}
        </option>
      </select>
      <i class="fas fa-caret-down custom-select-box-icon"></i>
    </div>
    <div class="row ml-2 mr-2">
      <div class="col"><h6>Summiert:</h6></div>
      <div class="col">
        <h6>{{ sumWorkingTimes }}h</h6>
      </div>
    </div>
    <div class="orderItemsContainer selfresult">
      <div
        *ngFor="let workingTime of workingTimes"
        class="correctioncontainer selfresult mb-2"
      >
        <div class="row">
          <div class="col">{{ workingTime.orderItemNo }}</div>
          <div class="col">{{ workingTime.time }}h</div>
        </div>
      </div>
    </div>
  </div>
</div>
