<mat-dialog-actions>
  <app-back-button mat-dialog-close></app-back-button>
  <app-button (click)="zoomIn()" [title]="'Zoom in'"></app-button>
  <app-button (click)="zoomOut()" [title]="'Zoom out'"></app-button>
</mat-dialog-actions>
<br>
<mat-dialog-content>
  <div #pdfContainer class="pdf-container">
  <pdf-viewer #pdfViewer [src]="data.pdfUrl.changingThisBreaksApplicationSecurity"
  [style.width.%]="100"
  [style.height.%]="100"
  [render-text]="true"
  [zoom]="zoom"
  [original-size]="false">
</pdf-viewer>
</div>
</mat-dialog-content>
