import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const AUTH_API = environment.API_ENDPOINT + '/user/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  getUsers(): Observable<any> {
    return this.http.get(AUTH_API)
  }

  addUser(username: string, lastName: string, firstName: string, info: string): Observable<any> {
    return this.http.post(
      AUTH_API,
      {
        username: username,
        lastName: lastName,
        firstName: firstName,
        info: info
      }
    );
  }

  setPassword(oldPassword: string, newPassword: string): Observable<any> {
    return this.http.post(
      AUTH_API+"changePW",
      {
        oldPW: oldPassword,
        newPW: newPassword
      }
    );
  }

}
