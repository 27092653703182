import { MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from 'src/app/modal/modal.component';
import { OrderItem } from 'src/app/screens/order-item-overview/order-item-overview.component';
import { OrderService } from 'src/app/services/order.service';
import { OnErrorTask } from 'src/app/tasks/on-error-task';
import { OnSuccessTask } from 'src/app/tasks/on-success-task';
import { Task } from '../task';

export class DeleteOrderitemTimeTask implements Task {
  private orderitemTime: any | null = null;
  private orderService: OrderService | null = null;
  private delegateSuccess: OnSuccessTask | undefined;
  private delegateError: OnErrorTask | undefined;

  async execute(dialogRef?: MatDialogRef<ModalComponent>): Promise<void> {
    if (this.orderitemTime != null && this.orderService != null) {
      this.orderService.deleteTime(this.orderitemTime.id).subscribe((result) => {
        dialogRef?.componentInstance?.enableTaskLoading(false);
        dialogRef?.close(true);

        this.delegateSuccess?.onSuccess(result);

        console.log('Executed ' + DeleteOrderitemTimeTask.name);
      });
    } else {
      dialogRef?.componentInstance?.enableTaskLoading(false);
      dialogRef?.close(true);

      this.delegateError?.onError();
    }
  }

  setValues(
    orderItem: OrderItem,
    orderService: OrderService,
    delegateSuccess: OnSuccessTask,
    delegateError: OnErrorTask
  ) {
    this.orderitemTime = orderItem;
    this.orderService = orderService;
    this.delegateSuccess = delegateSuccess;
    this.delegateError = delegateError;
  }
}
