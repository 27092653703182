import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ModalComponent } from 'src/app/modal/modal.component';
import { SocketioService } from 'src/app/services/socketio.service';
import { Task } from '../task';
import { AbstractTask } from './abstract.task';

export class RefreshOrderTask extends AbstractTask implements Task {
  protected executeAction(
    dialogRef?: MatDialogRef<ModalComponent, any> | undefined
  ): Promise<void> {
    this.socketService.loadOrderItems();
    return Promise.resolve();
  }

  constructor(
    private socketService: SocketioService,
    translate: TranslateService,
    dialog: MatDialog
  ) {
    super(dialog, translate);
  }
}
