import {
  HttpEvent, HttpHandler,
  HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ModalButtonGenerator } from '../modal/buttons/impl/modal-button-generator';
import { ModalComponent } from '../modal/modal.component';
import { TokenStorageService } from '../services/token-storage.service';

//const TOKEN_HEADER_KEY = 'Authorization'; // for Spring Boot
const TOKEN_HEADER_KEY = 'x-access-token';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private token: TokenStorageService, private dialog: MatDialog, private translate: TranslateService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let authReq = request;
    const token = this.token.getToken();
    if (token != null) {
      authReq = request.clone({
        headers: request.headers.set(TOKEN_HEADER_KEY, token),
      });
    }
    return next.handle(authReq).pipe(
      catchError((err) => {
        if (err.error.code) {
          err.error.message = this.translate.instant(`error-messages.${err.error.code}`, err.error.messages);
        }
        else {
          err.error.message = this.translate.instant(`error-messages.InternalError`, {});
        }

        let message = '<i class="fas fa-exclamation-triangle"></i><br>' + err.error.message;
        const dialogRef = this.dialog.open(ModalComponent, {
          data: {
            message,
            buttons: [ModalButtonGenerator.createCancelButton()],
          },
        });

        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
          console.log('The dialog was closed');
        });
        return throwError(err);
      })
    );
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];
