import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import {Router} from "@angular/router"

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.css']
})
export class UserAddComponent implements OnInit {

  form: any = {
    username: null,
    lastName: null,
    firstName: null,
    info: null,
  };
  loading = false;
  savedSuccess = false;
  savedError = false;

  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  onSubmit(f: NgForm): void {
    const {
      username,
      lastName,
      firstName,
      info,
    } = this.form;

    this.userService.addUser(
        username,
        lastName,
        firstName,
        info
      )
      .subscribe(
        (data) => {
          this.loading = false;
          this.savedSuccess = true;
          this.router.navigate(['/userOverview'])
        },
        (err) => {
          this.loading = false;
          this.savedError = true;
        }
      );
  }

}
