import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  private currentUserSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    localStorage.getItem(USER_KEY)
  );
  public currentUserObs: Observable<any> = this.currentUserSubject.asObservable();

  constructor() {}

  signOut(): void {
    localStorage.clear();
    this.currentUserSubject.next(null);
  }

  public saveToken(token: string): void {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    return localStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user: any): void {
    localStorage.removeItem(USER_KEY);
    localStorage.setItem(USER_KEY, JSON.stringify(user));
    this.currentUserSubject.next(localStorage.getItem(USER_KEY));
  }

  public getUser(): any {
    const user = localStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }
    return {};
  }
}
