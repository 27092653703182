import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[lineBreakCutoffEllipsis]'
})
export class LineBreakCutoffDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    const element = this.el.nativeElement;
    const charsPerLine = 30

    for (let i=0; i < Math.floor(element.innerText.length/charsPerLine); i++) {
      element.innerText = [element.innerText.slice(0, charsPerLine * (i+1)), element.innerText.slice(charsPerLine * (i+1))].join('\n')
    }
    const maxLines = 2;
    const lines = element.innerText.split('\n');
    if (lines.length > maxLines) {
      const truncatedText = lines.slice(0, maxLines).join('\n') + '...';
      element.innerText = truncatedText;
      }
    }
  }
