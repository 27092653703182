import { formatDate } from '@angular/common';
import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ChartType } from 'angular-google-charts';
import { OrderService } from '../../services/order.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { PdfViewComponent } from '../pdf-view/pdf-view.component';

@Component({
  selector: "app-order-item-detail",
  templateUrl: "./order-item-detail.component.html",
  styleUrls: ["./order-item-detail.component.css"],
})
export class OrderItemDetailComponent implements OnInit {
  pdfUrl: SafeResourceUrl | null = null;
  orderItemId!: string | null;
  orderItemNo!: string | null;
  orderItemDummy: any = {
    id: "",
    kunde: "",
    auftragsnr: "",
    bestellnr: "",
    arbeitszeit: "",
    geplanterTermin: "",
    bearbeitungszustand: "",
    overtime: "",
    assigned: null,
    noUserTime: false,
  };
  currentOrderItem = this.orderItemDummy;
  currentOrderItemUserData?: any[];
  detailsloading = false;

  currentWorkingDetailsViewIndex: number = 0;

  myChartTitle = "Arbeitsverteilung";
  myChartType = ChartType.PieChart;
  myChartData = [["", 0]];
  myChartColumns = ["Name", "Arbeitszeit"];

  form = {
    customer: null,
    orderItemNo: null,
    orderNo: null,
    executionState: "",
    executionEndTime: "",
    workingTime: "",
    plannedEndtime: "",
    noUserTime: "",
    description: null,
    identifier: null,
    remarks: null,
    plannedDuration: 0,
    forecastEndTS: "",
    warningBoundary: 0,
    pdfLink: "",
    lfaId: null,
    asfId: null,
    ausId: null,
    machineName: "",
  };

  initialForm: any;

  formDisabled = true;
  formSubmitBtnText = "Daten ändern";
  loading = false;

  toggleFormEditing() {
    this.formDisabled = !this.formDisabled;
    this.formSubmitBtnText = this.formDisabled
      ? "Daten ändern"
      : "Daten speichern";
  }

  constructor(
    private route: ActivatedRoute,
    private orderService: OrderService,
    private dialog: MatDialog,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.orderItemId = this.route.snapshot.paramMap.get("id");
    this.orderItemNo = this.route.snapshot.paramMap.get("orderitemno");

    this.loadData();
  }

  transformExecutionState(executionState: any): string {
    let workInProgress = "In Arbeit";
    let workDone = "Abgeschlossen";

    if (typeof executionState == "string") {
      return executionState < "400" ? workInProgress : workDone;
    }
    if (typeof executionState == "number") {
      return executionState < 400 ? workInProgress : workDone;
    }

    return executionState;
  }

  openPdf(): void {
    // Get PDF Link form oder service and load the file from the Backend
    let pdfLink = this.orderService.getPdfRestLink(this.orderItemId!);
    this.http.get(pdfLink, { responseType: 'blob' })
    .subscribe((blob: Blob) => {
      this.displayPdf(blob);
    });
  }

  displayPdf(blob: Blob) {
    // Convert into PDF Blob and serve as SafeURL within the client
    const pdfBlob = new Blob([blob], { type: 'application/pdf' });
    this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(pdfBlob));
    console.log(this.pdfUrl)
    this.dialog.open(PdfViewComponent, {
      width: '100%',
      height: '80%',
      data: { pdfUrl: this.pdfUrl },
    });
  }



  transformNoUserTime(noUserTime: boolean): string {
    if (noUserTime) {
      return "Ja";
    }

    return "Nein";
  }

  aufwandStundenInTage(stunden: number): number {
    return Math.floor(stunden / 24);
  }

  aufwandTageInStunden(tage: number): number {
    return tage * 24;
  }

  loadData(): void {
    this.orderService
      .getOrderDetailsByID(this.orderItemId)
      .subscribe((data: any) => {
        console.log(data)
        this.form.customer = data["kunde"];
        this.form.orderItemNo = data["auftragsnr"];
        this.form.orderNo = data["bestellnr"];
        this.form.executionState = this.transformExecutionState(
          data["bearbeitungszustand"]
        );
        if (data["abschluss"]) {
          this.form.executionEndTime = formatDate(
            data["abschluss"],
            "YYYY-MM-dd",
            "en-US"
          );
        } else {
          this.form.executionEndTime = new Date().toISOString();
        }
        this.form.plannedEndtime = formatDate(
          data["geplanterTermin"],
          "YYYY-MM-dd",
          "en-US"
        );
        this.form.forecastEndTS = formatDate(
          data["forecastEndDate"],
          "YYYY-MM-dd",
          "en-US"
        );
        this.form.workingTime = `${data["arbeitszeit"]} Std`;
        this.form.description = data["anmerkungen"];
        this.form.noUserTime = this.transformNoUserTime(data["noUserTime"]);
        this.form.identifier = data["identifier"];
        this.form.remarks = data["remarks"];
        this.form.plannedDuration = this.aufwandStundenInTage(data["aufwand"]);
        this.form.warningBoundary = data["warningBoundary"]
        this.form.pdfLink = data["pdfLink"]

        this.form.lfaId = data["lfaId"]
        this.form.asfId = data["asfId"]
        this.form.ausId = data["ausId"]
        this.form.machineName = data["machineName"]

        this.initialForm = { ...this.form };

        this.currentOrderItem = data;
        this.currentOrderItemUserData = data.userData;
        this.myChartData = [];
        for (
          let index = 0;
          index < this.currentOrderItemUserData!.length;
          index++
        ) {
          const userData = this.currentOrderItemUserData![index];
          this.myChartData.push([
            userData["displayName"],
            Math.round(userData["workingTime-raw"] * 100) / 100,
          ]);
        }
        this.detailsloading = false;
      });
  }

  async updateData(f: NgForm): Promise<void> {
    this.toggleFormEditing();

    if (this.formDisabled) {
      this.loading = true;
      try {
        let { description, plannedEndtime, remarks, plannedDuration, warningBoundary } = this.form;
        let orderItemDto: any = {
          anmerkungen: description,
          geplanterTermin: plannedEndtime,
          remarks: remarks,
          aufwand: this.aufwandTageInStunden(plannedDuration),
          warningBoundary: warningBoundary
        };
        await this.orderService
          .updateOrderItemDetails(this.orderItemId, orderItemDto)
          .toPromise();
        this.initialForm = { ...this.form };
      } catch (error) {
        console.error(error);
        this.resetFormValues();
      }
      this.loading = false;
    }
  }

  resetForm(f: NgForm) {
    this.resetFormValues();
    this.toggleFormEditing();
  }

  resetFormValues() {
    this.form = { ...this.initialForm };
  }

  toggleWorkingDetailsView(index: number) {
    switch (index) {
      case 0:
        this.currentWorkingDetailsViewIndex = 0;
        break;
      case 1:
        this.currentWorkingDetailsViewIndex = 1;
        break;
      default:
        this.currentWorkingDetailsViewIndex = -1;
        break;
    }
  }
}
