import { ButtonType } from 'src/app/enums/button-type.enum';
import { CancelTask } from 'src/app/tasks/impl/cancel.task';
import { OnErrorTask } from 'src/app/tasks/on-error-task';
import { Task } from 'src/app/tasks/task';
import { ModalButton } from '../modal-button';

export class ModalButtonGenerator {
  static createButton(text: string, task: Task, type: ButtonType): ModalButton {
    return {
      text,
      task,
      type,
    };
  }

  static createOkButton(task: Task): ModalButton {
    return this.createButton('Ok', task, ButtonType.PRIMARY);
  }

  static createCancelButton(delegate?: OnErrorTask): ModalButton {
    return this.createButton('Abbrechen', new CancelTask(delegate), ButtonType.SECONDARY);
  }
}
