import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { IButton } from 'src/app/components/buttons/button/button.component';
import { INavigateButton } from 'src/app/components/buttons/navigate-button/navigate-button.component';
import { OrderItemEvent } from 'src/app/components/orderitem-card/orderitem-card.component';
import { ButtonIconType } from 'src/app/enums/button-icon-type.enum';
import { ButtonType } from 'src/app/enums/button-type.enum';
import { OrderService } from 'src/app/services/order.service';
import { RefreshOrderTask } from 'src/app/tasks/impl/refresh-orders.task';
import {
  InfoDialogState,
  InfoDialogText
} from '../../enums/info-dialog.enum';
import { OrderItemProcessEnum } from '../../enums/orderitemprocess.enum';
import { ModalButtonGenerator } from '../../modal/buttons/impl/modal-button-generator';
import { ModalComponent } from '../../modal/modal.component';
import { AttributeManagerService } from '../../services/attribute-manager.service';
import { OrderitemService } from '../../services/orderitem.service';
import { SocketioService } from '../../services/socketio.service';
import { TokenStorageService } from '../../services/token-storage.service';
import { RunOrderItemUserlessTask } from '../../tasks/impl/run-orderitem-userless.task';

export interface OrderItem {
  id: string;
  kunde: string;
  auftragsnr: string;
  bestellnr: string;
  arbeitszeit: string;
  geplanterTermin: Date;
  bearbeitungszustand: string;
  overtime: number;
  assigned: boolean;
  assignees: any[];
  anmerkungen: string | null;
  noUserTime: boolean;
  forecastEndDate: Date;
  running_userless: boolean;
}

interface User {
  name: string;
  vorname: string;
  token: string;
  tokenExpiration: Date;
  arbeitszeit: string;
}

export interface GeneralDate {
  monat: string;
  woche: number;
  datum: Date;
}

@Component({
  selector: "app-order-item-overview",
  templateUrl: "./order-item-overview.component.html",
  styleUrls: ["./order-item-overview.component.css"],
})
export class OrderItemOverviewComponent implements OnInit {
  orderItems: OrderItem[] = [];
  genDate!: GeneralDate;
  user!: User;
  orderItemOvertime: number = 0;
  loading!: boolean;
  areas: any[] = [];
  machines: any[] = [];
  selectedArea: any;
  selectedMachine: any;
  selectedOrderNo: string | null = null;
  groupedOrderItems: any[] = [];

  monthNames = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ];

  currentUser: any;
  isAdmin = false;

  direction: string = "asc";
  column: string = "geplanterTermin";
  type: string = "date";

  orderItemFilterType: string = "default";

  createUserlessOption = "createuserless";

  infodialogbar: any = {
    state: InfoDialogState.DEFAULT,
    text: InfoDialogText.DEFAULT,
  };

  modal: ModalComponent | undefined;

  addOrderBtn: INavigateButton = {
    btnType: ButtonType.PRIMARY,
    route: ["/addOrder"],
    title: "Auftrag hinzufügen",
    iconType: ButtonIconType.ADD,
  };

  refreshOrderBtn: IButton = {
    btnType: ButtonType.SECONDARY,
    title: "Neuladen",
    iconType: ButtonIconType.REFRESH,
    customClasses: "float-right",
    task: new RefreshOrderTask(
      this.socketService,
      this.translateService,
      this.dialog
    ),
  };

  constructor(
    private socketService: SocketioService,
    private token: TokenStorageService,
    private attributeHandler: AttributeManagerService,
    private orderItemService: OrderitemService,
    private orderService: OrderService,
    private dialog: MatDialog,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.token.currentUserObs.subscribe((user: any) => {
      this.currentUser = JSON.parse(user);
      this.isAdmin = this.currentUser.roles.includes("ROLE_ADMIN");
    });

    this.setCurrentDate();

    this.socketService.orderItemsObs.subscribe((data: OrderItem[]) => {
      this.orderItems = data;
      console.log("Orderitems loaded", this.orderItems);

      if (data && data.length > 0) {
        let groupedOrderItemIds = [
          ...new Set(data.map((d) => d["auftragsnr"])),
        ].sort();
        let storedOrderItemFilter = localStorage.getItem("order-item-filter");
        if (storedOrderItemFilter) {
          this.selectedOrderNo = storedOrderItemFilter;
        }
        this.groupedOrderItems = groupedOrderItemIds.map((v) => {
          return {
            value: v,
            name: v,
          };
        });
        this.groupedOrderItems.unshift({
          value: null,
          name: "screens.orderitemOverview.orderItemIdFilter.all",
        });
      }
    });

    this.socketService.loadingObs.subscribe((data: boolean) => {
      this.loading = data;
    });

    if (!this.socketService.socketInitialzied) {
      this.socketService.setupSocketConnection();
    }

    this.attributeHandler.showTopbar();

    this.orderService.getAreas().subscribe(async (areas) => {
      this.areas = areas as any[];
      this.areas.forEach((area) => {
        if (area.selected === true) {
          this.selectedArea = area;
        }
      });
      this.areas.unshift({
        id: -1,
        name: "screens.orderitemOverview.areaFilter.all",
      });
      let machines: any = await this.orderService.getMachines().toPromise();
      this.machines = machines as any[];
      if (machines && machines.length > 0) {
        this.machines.forEach((machine) => {
          if (machine.selected === true) {
            this.selectedMachine = machine;
          }
        });
      }
      this.machines.unshift({
        id: -1,
        value: "screens.orderitemOverview.machineFilter.all",
      });
    });
  }

  private setCurrentDate() {
    const currentDate = new Date();

    // Copy date so don't modify original
    var d = new Date(
      Date.UTC(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      )
    );
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(
      ((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
    );

    this.genDate = {
      monat: this.monthNames[currentDate.getMonth()],
      woche: weekNo,
      datum: currentDate,
    };
  }

  orderItemEvent(orderItemEvent: OrderItemEvent) {
    switch (orderItemEvent.event) {
      case "start":
        this.startOrderItem(orderItemEvent.orderItem);
        break;
      case "startUserless":
        this.startOrderItemUserless(orderItemEvent.orderItem);
        break;
      case "pause":
        this.pauseOrderItem(orderItemEvent.orderItem);
        break;
      case "pauseUserless":
        this.pauseOrderItemUserless(orderItemEvent.orderItem);
        break;
      case "stop":
        this.stopOrderItem(orderItemEvent.orderItem);
        break;
      case "check":
        this.checkOrderItem(orderItemEvent.orderItem);
        break;
      default:
        break;
    }
  }

  openRunUserlessDialog(orderItem: OrderItem) {
    if (orderItem.bearbeitungszustand == "300") {
      let runUserLessTask = new RunOrderItemUserlessTask();
      runUserLessTask.setValues(
        orderItem,
        this.orderItemService,
        this.infodialogbar
      );
      let okButton = ModalButtonGenerator.createOkButton(runUserLessTask);
      let cancelButton = ModalButtonGenerator.createCancelButton();

      const dialogRef = this.dialog.open(ModalComponent, {
        data: {
          message: "Auftrag mannlos starten?",
          buttons: [okButton, cancelButton],
        },
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        console.log("The dialog was closed");
      });
    }
  }

  startOrderItem(orderItem: OrderItem) {
    this.orderItemService.handleOrderItem(
      OrderItemProcessEnum.START,
      orderItem,
      this.infodialogbar
    );
  }

  startOrderItemUserless(orderItem: OrderItem) {
    this.orderItemService.handleOrderItem(
      OrderItemProcessEnum.STARTUSERLESS,
      orderItem,
      this.infodialogbar
    );
  }

  pauseOrderItemUserless(orderItem: OrderItem) {
    this.orderItemService.handleOrderItem(
      OrderItemProcessEnum.PAUSEUSERLESS,
      orderItem,
      this.infodialogbar
    );
  }

  pauseOrderItem(orderItem: OrderItem) {
    this.orderItemService.handleOrderItem(
      OrderItemProcessEnum.PAUSE,
      orderItem,
      this.infodialogbar
    );
  }

  stopOrderItem(orderItem: OrderItem) {
    if (orderItem.bearbeitungszustand == "400") {
      this.checkOrderItem(orderItem);
    } else {
      this.orderItemService.handleOrderItem(
        OrderItemProcessEnum.STOP,
        orderItem,
        this.infodialogbar
      );
    }
  }

  checkOrderItem(orderItem: OrderItem) {
    this.orderItemService.handleOrderItem(
      OrderItemProcessEnum.CHECK,
      orderItem,
      this.infodialogbar
    );
  }

  manuallyReload() {
    this.socketService.loadOrderItems();
  }

  isKritisch(orderItem: OrderItem): boolean {
    orderItem.overtime =
      this.genDate.datum.getTime() - orderItem.geplanterTermin.getTime();
    const tolerance = -1 * 1000 * 60 * 60 * 48;
    if (orderItem.overtime < tolerance) {
      return false;
    } else {
      return true;
    }
  }

  // in hours
  getUeberschreitung(orderItem: OrderItem) {
    return (orderItem.overtime / 1000 / 60 / 60 / 24).toFixed(0);
  }

  getUeberschreitungText(orderItem: OrderItem) {
    let overtime: string = this.getUeberschreitung(orderItem);
    let overtimeText = "";
    try {
      if (Number(overtime) >= 0) {
        overtimeText += "+";
      }
      overtimeText += overtime + " ";
      overtimeText += "Tag(e)";
    } catch (error) {
      overtimeText = "Fehler";
    }
    return overtimeText;
  }

  filterOrderItems(event: any) {
    if (event) {
      if (event.target) {
        this.orderItemFilterType = event.target.value;
      }
    }
  }

  areaChanged() {
    this.orderService.updateUserArea(this.selectedArea.id).subscribe(() => {
      this.manuallyReload();
    });
  }

  machineChanged() {
    this.orderService
      .updateUserMachine(this.selectedMachine.id)
      .subscribe(() => {
        this.manuallyReload();
      });
  }

  orderItemNoFilterChanged() {
    if (this.selectedOrderNo) {
      localStorage.setItem("order-item-filter", this.selectedOrderNo);
    } else {
      localStorage.removeItem("order-item-filter");
    }
  }
}
