<div class="features-boxed resultdetail">
  <div class="container mt-3">
    <div class="row">
      <div class="col">
        <button
          class="btn btn-custom btn-outline btnBack"
          type="button"
          [routerLink]="['/settings']"
        >
          <i class="fa fa-chevron-left"></i>
          <span class="ml-1">Einstellungen</span>
        </button>
      </div>
    </div>
  </div>
  <div class="info-section">
    <div class="info-section-container">
      <h2 class="info-header">Passwort ändern</h2>
      <div class="info-container">
        <form
          class="info-container-form"
          name="form"
          (ngSubmit)="f.form.valid && onSubmit(f)"
          [appPasswordMatch] = "['newPW', 'newPWCheck']"
          #f="ngForm"
        >
          <div class="info-container-form-entry">
            <label class="info-container-form-entry-item" for="oldPW"
              >Altes Passwort</label
            >
            <input
              class="info-container-form-entry-item"
              type="password"
              id="oldPW"
              [(ngModel)]="form.oldPW"
              name="oldPW"
              required
              #oldPW="ngModel"
            />
          </div>
          <div class="info-container-form-entry">
            <label class="info-container-form-entry-item" for="newPW"
              >Neues Passwort</label
            >
            <input
              class="info-container-form-entry-item"
              type="password"
              id="newPW"
              [(ngModel)]="form.newPW"
              name="newPW"
              required
              #newPW="ngModel"
            />
          </div>
          <div class="info-container-form-entry">
            <label class="info-container-form-entry-item" for="newPWCheck"
              >Bestätigung neues Passwort</label
            >
            <input
              class="info-container-form-entry-item"
              type="password"
              id="newPWCheck"
              [(ngModel)]="form.newPWCheck"
              name="newPWCheck"
              required
              #newPWCheck="ngModel"
            />
          </div>
          <div *ngIf="f.submitted && oldPW.errors" class="alert alert-danger">
            <div *ngIf="oldPW.errors.required">Altes Passwort ist erfolderlich!</div>
          </div>
          <div *ngIf="f.submitted && newPW.errors" class="alert alert-danger">
            <div *ngIf="newPW.errors.required">Neues Passwort ist erfolderlich!</div>
          </div>
          <div *ngIf="f.submitted && newPWCheck.errors" class="alert alert-danger">
            <div *ngIf="newPWCheck.errors.required">Bestätigung neues Passwort ist erfolderlich!</div>
          </div>
          <div *ngIf="f.submitted && newPWCheck.errors?.passwordMismatch" class="alert alert-danger">
            <div>Passwörter stimmen nicht überein</div>
          </div>
          <div class="info-container-form-entry css-submit">
            <button
              type="submit"
              name="submit-btn"
              class="info-container-form-btn"
            >
              <span>Passwort ändern</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
