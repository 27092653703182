import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Multiplier } from '../screens/multiplier/multiplier.component';

const AUTH_API = environment.API_ENDPOINT + '/multiplier/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class MultiplierService {
  constructor(private http: HttpClient) {}

  getTimezones(): Observable<any> {
    return this.http.get(AUTH_API + 'timezones');
  }

  addTimezone(multiplier: Number): Observable<any> {
    return this.http.post(AUTH_API + 'timezones', { multiplier: multiplier });
  }

  addMultiplier(multiplier: Multiplier, timezone: string): Observable<any> {
    return this.http.post(
      AUTH_API,
      {
        dayofweek: multiplier.dayOfWeek,
        timefrom: multiplier.startTimeTS,
        timeto: multiplier.endTimeTS,
        multiplier: multiplier.factor,
        timezone: timezone,
      },
      httpOptions
    );
  }

  removeTimezone(id: string): Observable<any> {
    return this.http.delete(AUTH_API + 'timezones' + '/' + id);
  }

  removeMultiplier(id: string): Observable<any> {
    return this.http.delete(AUTH_API + id);
  }

  updateTimezone(id: string, multiplier: Number): Observable<any> {
    return this.http.patch(AUTH_API + 'timezones', {
      id: id,
      factor: multiplier,
    });
  }
}
