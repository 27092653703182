<div class="features-boxed mb-5 settings">
  <div class="form-container">
    <div class="image-holder"></div>
    <form method="post" class="container">
      <div class="intro small">
        <h3 class="title">Einstellungen</h3>
      </div>
      <div class="intro large">
        <h3 class="text-center title">Einstellungen</h3>
      </div>
      <div class="orderItemsContainer settings">
        <ul class="list-group">
          <li class="btn btn-custom btn-outline mb-2" [routerLink]="['/multiplier']" *ngIf="isAdmin && showTimezonesBtn">
            <div class="row m-0 p-0">
              <div class="col-8 col-sm-11 text-center">
                <span>Zeitzonen</span><span>&nbsp;anpassen</span>
              </div>
              <div class="col-4 col-sm-1 text-right">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
          </li>
          <li class="btn btn-custom btn-outline mb-2" [routerLink]="['/userOverview']" *ngIf="isAdmin">
            <div class="row m-0 p-0">
              <div class="col-8 col-sm-11 text-center">
                <span>Nutzer</span><span>&nbsp;verwalten</span>
              </div>
              <div class="col-4 col-sm-1 text-right">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
          </li>

          <li *ngIf="showPwResetBtn" class="btn btn-custom btn-outline mb-2" [routerLink]="['/pwReset']">
            <div class="row m-0 p-0">
              <div class="col-8 col-sm-11 text-center">
                <span>Passwort</span><span>&nbsp;ändern</span>
              </div>
              <div class="col-4 col-sm-1 text-right">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
          </li>



          <!--li class="btn btn-custom btn-outline mb-2" *ngIf="isAdmin">
                    <div class="row m-0 p-0">
                        <div class="col-8 col-sm-11 text-center">
                            <span>Benutzer</span><span>&nbsp;verwalten</span>
                        </div>
                        <div class="col-4 col-sm-1 text-right">
                            <i class="fa fa-chevron-right"></i>
                        </div>
                    </div>
                </li-->
          <li class="btn btn-custom btn-outline" (click)="logout()">
            <div class="row m-0 p-0">
              <div class="col-8 col-sm-11 text-center">
                <span>Abmelden</span><span>&nbsp;erzwingen</span>
              </div>
              <div class="col-4 col-sm-1 text-right">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
          </li>
          <li class="btn">
            <div class="row m-0 p-0">
              <div class="col-12">
                <small class="text-muted">
                  <i class="fas fa-info-circle"></i>
                  v{{ version }}
                </small>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </form>
  </div>
</div>
