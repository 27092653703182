import { Injectable } from '@angular/core';
import { buildInfoDialog, InfoDialog } from '../enums/info-dialog.enum';
import { OrderItemProcessEnum } from '../enums/orderitemprocess.enum';
import { OrderItem } from '../screens/order-item-overview/order-item-overview.component';
import { SocketioService } from './socketio.service';

@Injectable({
  providedIn: 'root'
})
export class OrderitemService {

  createUserlessOption = 'createuserless';

  constructor(
    private socketService: SocketioService,
  ) { }

  private isAllowedToStart(orderItem: OrderItem): boolean {
    if ([100, 300, 400].indexOf(parseInt(orderItem.bearbeitungszustand)) > -1) {
      return true;
    } else if (
      200 == parseInt(orderItem.bearbeitungszustand) &&
      (!orderItem.assigned || !orderItem.running_userless)
    ) {
      return true;
    }
    return false;
  }

  private isAllowedToPause(orderItem: OrderItem): boolean {
    let oldState = false;
    if (orderItem.bearbeitungszustand == '200') {
      oldState = true;
    }

    let assignment = false;
    if (orderItem.assigned || orderItem.running_userless) {
      assignment = true;
    }

    return oldState && assignment;
  }

  handleOrderItem(
    action: OrderItemProcessEnum,
    orderItem: OrderItem,
    infodialogbar: any
  ) {
    let msg: InfoDialog | null = null;
    let options: any = {};

    console.log(action)

    switch (action) {
      case OrderItemProcessEnum.START: {
        if (this.isAllowedToStart(orderItem)) {
          orderItem.bearbeitungszustand = '200';
          msg = InfoDialog.ORDERITEMSTART;
        }
        break;
      }
      case OrderItemProcessEnum.STARTUSERLESS: {
        if (this.isAllowedToStart(orderItem)) {
          orderItem.bearbeitungszustand = '200';
          msg = InfoDialog.ORDERITEMSTARTUSERLESS;
        }
        break;
      }
      case OrderItemProcessEnum.PAUSEUSERLESS: {
        if (this.isAllowedToPause(orderItem)) {
          msg = InfoDialog.ORDERITEMPAUSEDUSERLESS;
        }
        break;
      }
      case OrderItemProcessEnum.PAUSE: {
        if (this.isAllowedToPause(orderItem)) {
          let newState = '300';
          if (orderItem.assignees.length > 1) {
            newState = '200';
          }
          orderItem.bearbeitungszustand = newState;
          msg = InfoDialog.ORDERITEMPAUSED;
        }
        break;
      }
      case OrderItemProcessEnum.STOP: {
        if (orderItem.bearbeitungszustand == '300') {
          let ok = confirm('Auftrag wirklich abschließen?');
          if (ok === true) {
            orderItem.bearbeitungszustand = '400';
            msg = InfoDialog.ORDERITEMSTOPPED;
          }
        }
        break;
      }
      case OrderItemProcessEnum.CHECK: {
        if (orderItem.bearbeitungszustand == '400') {
          let ok = confirm('Auftrag wirklich löschen?');
          if (ok === true) {
            orderItem.bearbeitungszustand = '500';
            msg = InfoDialog.ORDERITEMCHECKED;
          }
        }
        break;
      }
      default: {
        return;
      }
    }
    if (msg != null) {
      this.socketService.updateOrderItem(action, orderItem, options);
      buildInfoDialog(msg, infodialogbar);
      // event?.stopPropagation();
    }
  }
}
