import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { OrderItemDTO } from "../helpers/interfaces/orderitem-dto";

const ORDER_API = environment.API_ENDPOINT + "/order/";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: "root",
})
export class OrderService {
  constructor(private http: HttpClient) {}

  addOrder(orderitemDto: OrderItemDTO): Observable<any> {
    return this.http.post(ORDER_API + "orderItems", orderitemDto, httpOptions);
  }

  getOrders() {
    return this.http.get(ORDER_API + "orderItems");
  }

  getAreas() {
    return this.http.get(ORDER_API + "areas");
  }

  getMachines() {
    return this.http.get(ORDER_API + "machines");
  }

  updateUserArea(areaId: string) {
    return this.http.post(ORDER_API + "userarea", {
      area: areaId,
    });
  }

  updateUserMachine(machineId: string) {
    return this.http.post(ORDER_API + "usermachine", {
      machine: machineId,
    });
  }

  getOrderDetails(orderItem: any): Observable<any> {
    return this.http.get(ORDER_API + "orderItems/" + orderItem.id);
  }

  getOrderDetailsByID(orderItemid: any): Observable<any> {
    return this.http.get(ORDER_API + "orderItems/" + orderItemid);
  }

  updateOrderItemDetails(
    orderItemid: any,
    orderItemDto: OrderItemDTO
  ): Observable<any> {
    return this.http.post(
      ORDER_API + "orderItems/" + orderItemid,
      orderItemDto,
      httpOptions
    );
  }

  getTimes(id: string): Observable<any> {
    return this.http.get(ORDER_API + "orderItems/" + "correction/" + id);
  }

  updateTime(
    id: string,
    newFromDate: string,
    newFromTime: string,
    newToDate: string,
    newToTime: string
  ): Observable<any> {
    return this.http.post(
      ORDER_API + "orderItems/" + "correction/" + id,
      {
        id: id,
        fromDate: newFromDate,
        fromTime: newFromTime,
        toDate: newToDate,
        toTime: newToTime,
      },
      httpOptions
    );
  }

  deleteTime(id: string): Observable<any> {
    return this.http.delete(ORDER_API + "orderItems/" + "correction/" + id);
  }

  removeOrderItem(id: string): Observable<any> {
    return this.http.delete(ORDER_API + "orderItems/" + id, httpOptions);
  }

  getUserResults(month: string, year: string): Observable<any> {
    return this.http.get(
      environment.API_ENDPOINT + "/evaluation/" + "user/" + year + "/" + month
    );
  }

  getAllUserResults(month: string, year: string): Observable<any> {
    return this.http.get(
      environment.API_ENDPOINT +
        "/evaluation/" +
        "user/" +
        year +
        "/" +
        month +
        "/all"
    );
  }

  getWorkingTimes(): Observable<any> {
    return this.http.get(
      environment.API_ENDPOINT + "/evaluation/" + "usertimes"
    );
  }

  getPdfRestLink(orderItemId: string): string {
    return ORDER_API + "orderItems/" + "pdf/" + orderItemId
  }
}
