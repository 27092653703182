<div class="features-boxed resultdetail">
  <div class="container mt-3">
    <div class="row">
      <div class="col">
        <button
          class="btn btn-custom btn-outline btnBack"
          type="button"
          [routerLink]="['/userOverview']"
        >
          <i class="fa fa-chevron-left"></i>
          <span class="ml-1">Nutzer Übersicht</span>
        </button>
      </div>
    </div>
  </div>
  <div class="info-section">
    <div class="info-section-container">
      <h2 class="info-header">Nutzer hinzufügen</h2>
      <div class="info-container">
        <form
          class="info-container-form"
          name="form"
          (ngSubmit)="f.form.valid && onSubmit(f)"
          #f="ngForm"
        >
          <div class="info-container-form-entry">
            <label class="info-container-form-entry-item" for="username"
              >Nutzername</label
            >
            <input
              class="info-container-form-entry-item"
              type="text"
              id="usermane"
              [(ngModel)]="form.username"
              name="username"
              required
              #username="ngModel"
            />
          </div>
          <div class="info-container-form-entry">
            <label class="info-container-form-entry-item" for="orderItemNo"
              >Nachname</label
            >
            <input
              class="info-container-form-entry-item"
              type="text"
              id="lastName"
              [(ngModel)]="form.lastName"
              name="lastName"
              required
              #lastName="ngModel"
            />
          </div>
          <div class="info-container-form-entry">
            <label class="info-container-form-entry-item" for="orderNo"
              >Vorname</label
            >
            <input
              class="info-container-form-entry-item"
              type="text"
              id="firstName"
              [(ngModel)]="form.firstName"
              name="firstName"
              required
              #firstName="ngModel"
            />
          </div>
          <div class="info-container-form-entry">
            <label class="info-container-form-entry-item" for="executionState"
              >Info</label
            >
            <input
              class="info-container-form-entry-item"
              type="text"
              id="info"
              [(ngModel)]="form.info"
              name="info"
              #info="ngModel"
            />
          </div>
          <div *ngIf="f.submitted && username.errors" class="alert alert-danger">
            <div *ngIf="username.errors.required">Username ist erfolderlich!</div>
          </div>
          <div *ngIf="f.submitted && lastName.errors" class="alert alert-danger">
            <div *ngIf="lastName.errors.required">Nachname ist erfolderlich!</div>
          </div>
          <div *ngIf="f.submitted && firstName.errors" class="alert alert-danger">
            <div *ngIf="firstName.errors.required">Vorname ist erfolderlich!</div>
          </div>
          <div class="info-container-form-entry css-submit">
            <button
              type="submit"
              name="submit-btn"
              class="info-container-form-btn"
            >
              <span>Hinzufügen</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
