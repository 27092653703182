<app-splash-screen></app-splash-screen>
<nav class="bar topbar" [ngClass]="{ out: hideTopbar }" *ngIf="isLoggedIn" id="navbarContainer">
  <div class="container">
    <div class="row align-items-center py-2">
      <div class="col-6 logo-displayer">
        <img src="{{logoUrl}}" alt="" />
      </div>
      <div class="col-xs-12 col-6 d-flex cw-displayer">
        <span class="ml-auto" *ngIf="isLoggedIn"
          >KW {{ dateInfos.woche }}<br />{{
            dateInfos.datum | date: "dd.MM.YYYY"
          }}</span
        >
      </div>
    </div>
    <div class="row align-items-center pb-1 name-displayer-small">
      <div class="col d-flex">
        <span class="mx-auto">{{ vorname }} {{ name }}</span>
      </div>
    </div>
  </div>

  <div class="topbarContainer" *ngIf="isLoggedIn">
    <ul class="nav topbaritems">
      <li>
        <a class="nav-link" [routerLinkActive]="['active']" routerLink="/home"
          >Live</a
        >
      </li>
      <li>
        <a
          class="nav-link"
          [routerLinkActive]="['active']"
          routerLink="/results"
          >Auswertung</a
        >
      </li>
      <li>
        <a
          class="nav-link"
          [routerLinkActive]="['active']"
          routerLink="/settings"
          >Einstellungen</a
        >
      </li>
    </ul>
  </div>
</nav>
<div
  class="bar namebar name-displayer-large"
  [ngClass]="{ out: hideTopbar }"
  *ngIf="isLoggedIn"
>
  <div class="row align-items-end pb-1">
    <div class="col d-flex">
      <span class="mx-auto">{{ vorname }} {{ name }}</span>
    </div>
  </div>
</div>
<div class="bar timebar" [ngClass]="{ out: hideTopbar }" *ngIf="isLoggedIn">
  <div class="row align-items-end pb-1">
    <div class="col d-flex">
      <span class="ml-auto">{{ dateInfos.monat }}:</span>
    </div>
    <div class="col d-flex">
      <span class="mr-auto">{{ arbeitszeit }}h</span>
    </div>
  </div>
</div>

<!-- ng-template #dynamicComponent></ng-template -->

<router-outlet></router-outlet>

<div class="bar bottombar" *ngIf="isLoggedIn" id="bottombarContainer">
  <button
    type="button"
    name="orderItemBtn"
    [routerLinkActive]="['btnDark']"
    routerLink="/home"
    class="bottomBtn btnCircle btnLight d-flex justify-content-center align-items-center"
  >
    <!--i class="far fa-file-alt"></i -->
    <i class="far fa-dot-circle"></i>
    <span>Live</span>
  </button>
  <button
    type="button"
    name="orderItemBtn"
    [routerLinkActive]="['btnDark']"
    routerLink="/results"
    class="bottomBtn btnCircle btnLight d-flex justify-content-center align-items-center"
  >
    <i class="fa fa-history"></i>
    <span>Übersicht</span>
  </button>
  <button
    type="button"
    name="orderItemBtn"
    [routerLinkActive]="['btnDark']"
    routerLink="/settings"
    class="bottomBtn btnCircle btnLight d-flex justify-content-center align-items-center"
  >
    <i class="fa fa-cog"></i>
    <span>Einstellungen</span>
  </button>
  <span class="bottomSpan">{{ dateInfos.monat }}<br />{{ arbeitszeit }}h</span>
</div>

<!-- installation promotion
<div
  class="showInstallationPrompt"
  [ngClass]="{ show: showInstallationPrompt }"
>
  <div class="row mb-5">
    <div class="col d-flex">
      <div class="ml-auto p-3">
        <button
          class="btn closeInstallationPromptBtn"
          (click)="hideInstallPromotion()"
        >
          <i class="fas fa-times fa-lg"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="row mb-5">
    <div class="col">
      <div class="container text-center">
        <h3>Wollen Sie die Anwendung auf Ihrem Gerät installieren?</h3>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <button
        class="btn btn-primary d-block mx-auto showInstallationPromptBtn"
        (click)="installPromotion()"
      >
        Installieren
      </button>
    </div>
    <div class="col-6">
      <button
        class="btn btn-secondary d-block mx-auto showInstallationPromptBtn"
        (click)="hideInstallPromotion()"
      >
        Abbrechen
      </button>
    </div>
  </div>
</div>
-->
