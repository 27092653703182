<div class="features-boxed mb-5 multiplier">
  <div class="container mt-3">
    <div class="row">
      <div class="col">
        <button
          class="btn btn-custom btn-outline btnBack"
          type="button"
          [routerLink]="['/settings']"
        >
          <i class="fa fa-chevron-left"></i>
          <span class="ml-1">Einstellungen</span>
        </button>
      </div>
    </div>
  </div>
  <div class="form-container">
    <div class="image-holder"></div>
    <form method="post">
      <div class="intro small">
        <h3 class="title">Zeitzonen</h3>
      </div>
      <div class="intro large">
        <h3 class="text-center title">Zeitzonen&nbsp;verwalten.</h3>
      </div>
      <div class="container orderItemsContainer multiplier">
        <p class="timezonecontainer-pretext font-italic text-center">
          Wenn Arbeitszeiten in Zeiträumen erfasst erden, die keiner
          untenstehenden Zeitzone zugeordnet werden können, wird standardmäßig
          die Zeitzone 0 verwendet. In Zeitzone 0 wird der Multiplikator 1
          angewendet.
        </p>
        <p class="timezonecontainer-mobile-not-supported">
          <i class="fa fa-desktop"></i>
          Bitte größeres Anzeigegerät verwenden. Ansicht ist nicht für kleine Geräte freigeschaltet.
          <!--
          <br />
          <button class="btn btn-custom btn-outline">Trotzdem anzeigen</button>
          -->
        </p>
        <div
          class="timezonecontainer"
          *ngFor="let timezone of timezones; let i = index"
        >
          <div class="tctitle bg-custom">
            <span>Zeitzone {{ i + 1 }}</span>
          </div>
          <div>
            <h5>Multiplikator:</h5>
            <input
              class="form-group tcmultiplier"
              type="number"
              min="0"
              max="10"
              step="0.1"
              value="{{ timezone.multiplier }}"
              disabled
            />
            <button
              type="button"
              name="button"
              class="btn btn-custom mr-2"
              (click)="updateTimezone(timezone.id)"
            >
              <i class="fas fa-edit mr-1"></i>Ändern
            </button>
            <button
              class="btn btn-custom btn-outline"
              (click)="removeTimezone(timezone.id)"
            >
              <i class="far fa-trash-alt mr-1"></i>Löschen
            </button>
          </div>

          <div class="timezone-table">
            <div class="timezone-table-entry">
              <div>Tag</div>
              <div>Zeitraum (von)</div>
              <div>Zeitraum (bis)</div>
              <div>Bearbeiten</div>
            </div>
            <div
              class="timezone-table-entry"
              *ngFor="let multiplier of timezone.Multipliers"
            >
              <div>
                {{ multiplier.dayOfWeek }}
              </div>
              <div>
                <input
                  class="form-control"
                  type="time"
                  value="{{ multiplier.startTimeTS }}"
                  disabled
                />
              </div>
              <div>
                <input
                  class="form-control"
                  type="time"
                  value="{{ multiplier.endTimeTS }}"
                  disabled
                />
              </div>
              <div>
                <button
                  class="btn btn-custom btn-outline resultBtn"
                  (click)="removeMultiplier(multiplier.id)"
                >
                  <i class="far fa-trash-alt"></i>
                </button>
              </div>
            </div>
            <div class="timezone-table-entry">
              <div>
                <select class="form-control" #selectedDayOfweek>
                  <optgroup label="Tag wählen...">
                    <option value="1" selected>Montag</option>
                    <option value="2">Dienstag</option>
                    <option value="3">Mittwoch</option>
                    <option value="4">Donnerstag</option>
                    <option value="5">Freitag</option>
                    <option value="6">Samstag</option>
                    <option value="0">Sonntag</option>
                  </optgroup>
                </select>
              </div>
              <div>
                <input class="form-control" type="time" #timeFrom />
              </div>
              <div>
                <input class="form-control" type="time" #timeTo />
              </div>
              <div>
                <button
                  class="btn btn-custom resultBtn"
                  type="button"
                  (click)="
                    addMultiplier(
                      selectedDayOfweek,
                      timeFrom,
                      timeTo,
                      timezone.multiplier,
                      timezone._id
                    )
                  "
                >
                  <i class="fa fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="btn btn-custom timezoneadder" (click)="addTimezone()">
          <i class="fa fa-plus mr-1"></i> Zeitzone hinzufügen
        </div>
      </div>
    </form>
  </div>
</div>
