<div class="features-boxed resultdetail">
  <div class="container mt-3">
    <div class="row">
      <div class="col">
        <button
          class="btn btn-custom btn-outline btnBack"
          type="button"
          [routerLink]="['/results']"
        >
          <i class="fa fa-chevron-left"></i>
          <span class="ml-1">Zeiten</span>
        </button>
      </div>
    </div>
  </div>
  <div class="info-section">
    <div class="info-section-container">
      <h2 class="info-header">Auftragsdetails</h2>
      <div class="info-container">
        <form
          class="info-container-form"
          name="form"
          (ngSubmit)="updateData(f)"
          #f="ngForm"
          novalidate
        >
          <div class="info-container-form-entry">
            <label class="info-container-form-entry-item" for="customer"
              >{{ "screens.orderItemDetail.customer" | translate }}</label
            >
            <input
              class="info-container-form-entry-item"
              type="text"
              id="customer"
              [(ngModel)]="form.customer"
              name="customer"
              required
              #customer="ngModel"
              disabled
            />
          </div>
          <div class="info-container-form-entry">
            <label class="info-container-form-entry-item" for="orderItemNo"
              >{{ "screens.orderItemDetail.orderItemNo" | translate }}</label
            >
            <input
              class="info-container-form-entry-item"
              type="text"
              id="orderItemNo"
              [(ngModel)]="form.orderItemNo"
              name="orderItemNo"
              required
              #orderItemNo="ngModel"
              disabled
            />
          </div>
          <div *ngIf="form.identifier" class="info-container-form-entry">
            <label class="info-container-form-entry-item" for="identifier"
              >{{ "screens.orderItemDetail.identifier" | translate }}</label
            >
            <input
              class="info-container-form-entry-item"
              type="text"
              id="identifier"
              [(ngModel)]="form.identifier"
              name="identifier"
              required
              #identifier="ngModel"
              disabled
            />
          </div>
          <div *ngIf="form.identifier" class="info-container-form-entry">
            <label class="info-container-form-entry-item" for="lfaId"
              >{{ "screens.orderItemDetail.lfaId" | translate }}</label
            >
            <input
              class="info-container-form-entry-item"
              type="number"
              id="lfaId"
              [(ngModel)]="form.lfaId"
              name="lfaId"
              required
              #identifier="ngModel"
              disabled
            />
          </div>
          <div *ngIf="form.identifier" class="info-container-form-entry">
            <label class="info-container-form-entry-item" for="asfId"
              >{{ "screens.orderItemDetail.asfId" | translate }}</label
            >
            <input
              class="info-container-form-entry-item"
              type="number"
              id="asfId"
              [(ngModel)]="form.asfId"
              name="asfId"
              required
              #identifier="ngModel"
              disabled
            />
          </div>
          <div *ngIf="form.identifier" class="info-container-form-entry">
            <label class="info-container-form-entry-item" for="ausId"
              >{{ "screens.orderItemDetail.ausId" | translate }}</label
            >
            <input
              class="info-container-form-entry-item"
              type="number"
              id="ausId"
              [(ngModel)]="form.ausId"
              name="ausId"
              required
              #identifier="ngModel"
              disabled
            />
          </div>
          <div *ngIf="form.identifier" class="info-container-form-entry">
            <label class="info-container-form-entry-item" for="machineName"
              >{{ "screens.orderItemDetail.machineName" | translate }}</label
            >
            <input
              class="info-container-form-entry-item"
              type="text"
              id="machineName"
              [(ngModel)]="form.machineName"
              name="machineName"
              required
              #identifier="ngModel"
              disabled
            />
          </div>
          <div *ngIf="form.orderNo" class="info-container-form-entry">
            <label class="info-container-form-entry-item" for="orderNo"
              >{{ "screens.orderItemDetail.orderNo" | translate }}</label
            >
            <input
              class="info-container-form-entry-item"
              type="text"
              id="orderNo"
              [(ngModel)]="form.orderNo"
              name="orderNo"
              required
              #orderNo="ngModel"
              disabled
            />
          </div>
          <div class="info-container-form-entry">
            <label class="info-container-form-entry-item" for="executionState"
              >{{ "screens.orderItemDetail.executionState" | translate }}</label
            >
            <input
              class="info-container-form-entry-item"
              type="text"
              id="executionState"
              [(ngModel)]="form.executionState"
              name="executionState"
              required
              #executionState="ngModel"
              disabled
            />
          </div>
          <div
            *ngIf="form.executionState == 'Abgeschlossen'"
            class="info-container-form-entry"
          >
            <label class="info-container-form-entry-item" for="executionEndTime"
              >Abschluss</label
            >
            <input
              class="info-container-form-entry-item"
              type="date"
              id="executionEndTime"
              [(ngModel)]="form.executionEndTime"
              name="executionEndTime"
              required
              #executionEndTime="ngModel"
              disabled
            />
          </div>
          <div class="info-container-form-entry">
            <label class="info-container-form-entry-item" for="workingTime"
              >{{ "screens.orderItemDetail.workingTime" | translate }}</label
            >
            <input
              class="info-container-form-entry-item"
              type="text"
              id="workingTime"
              [(ngModel)]="form.workingTime"
              name="workingTime"
              required
              #workingTime="ngModel"
              disabled
            />
          </div>
          <div class="info-container-form-entry">
            <label class="info-container-form-entry-item" for="plannedDate"
              >{{ "screens.addOrder.plannedTS" | translate }}</label
            >
            <input
              class="info-container-form-entry-item"
              id="plannedDate"
              type="date"
              [(ngModel)]="form.plannedEndtime"
              name="plannedDate"
              required
              #plannedDate="ngModel"
              [disabled]="formDisabled"
            />
          </div>
          <div class="info-container-form-entry">
            <label class="info-container-form-entry-item" for="forecastEndTS"
              >{{ "screens.orderItemDetail.forecastEndTS" | translate }}</label
            >
            <input
              class="info-container-form-entry-item"
              id="forecastEndTS"
              type="date"
              [(ngModel)]="form.forecastEndTS"
              name="forecastEndTS"
              #forecastEndTS="ngModel"
              disabled
            />
          </div>
          <div class="info-container-form-entry">
            <label class="info-container-form-entry-item" for="comments"
              >{{ "screens.orderItemDetail.comments" | translate }}</label
            >
            <input
              class="info-container-form-entry-item"
              type="text"
              id="comments"
              [(ngModel)]="form.description"
              name="comments"
              #comments="ngModel"
              [disabled]="formDisabled"
            />
          </div>
          <div class="info-container-form-entry">
            <label class="info-container-form-entry-item" for="plannedDuration"
              >{{ "screens.orderItemDetail.plannedDuration" | translate }}</label
            >
            <input
              class="info-container-form-entry-item"
              id="plannedDuration"
              type="number"
              [(ngModel)]="form.plannedDuration"
              name="plannedDuration"
              required
              #plannedDate="ngModel"
              [disabled]="formDisabled"
            />
          </div>
          <div class="info-container-form-entry">
            <label class="info-container-form-entry-item" for="warningBoundary"
              >{{ "screens.orderItemDetail.warningBoundary" | translate }}</label
            >
            <input
              class="info-container-form-entry-item"
              id="warningBoundary"
              type="number"
              [(ngModel)]="form.warningBoundary"
              name="warningBoundary"
              required
              #warningBoundary="ngModel"
              [disabled]="formDisabled"
            />
          </div>
          <div class="info-container-form-entry">
            <label class="info-container-form-entry-item" for="remarks"
              >{{ "screens.orderItemDetail.remarks" | translate }}</label
            >
            <textarea
              class="info-container-form-entry-item"
              name="remarks"
              id="remarks"
              [(ngModel)]="form.remarks"
              #remarks="ngModel"
              [disabled]="formDisabled"
              cols="50"
              rows="10"
            ></textarea>
          </div>
          <div class="info-container-form-entry">
            <label class="info-container-form-entry-item" for="noUserTime"
              >Intern</label
            >
            <input
              class="info-container-form-entry-item"
              type="text"
              id="noUserTime"
              [(ngModel)]="form.noUserTime"
              name="noUserTime"
              #noUserTime="ngModel"
              disabled
            />
          </div>
          <div class="info-container-form-entry">
            <label class="info-constainer-form-entry-item" for="noUserTime"
              >{{ "screens.orderItemDetail.pdfLink" | translate }}</label
            >
              <a href="javascript:void(0)" (click)="openPdf()">{{ form.pdfLink }}</a>
          </div>
          <div
            *ngIf="form.executionState != 'Abgeschlossen'"
            class="info-container-form-entry css-submit"
          >
            <button
              type="submit"
              name="submit-btn"
              class="info-container-form-btn"
              [disabled]="loading"
            >
              <i
                *ngIf="!loading"
                class="fas"
                [ngClass]="{
                  'fa-edit': formDisabled,
                  'fa-cloud-download-alt': !formDisabled
                }"
              ></i>
              <i
                *ngIf="loading"
                class="spinner-border spinner-border-sm mr-1"
              ></i>
              <span>{{ formSubmitBtnText }}</span>
            </button>
            <button
              *ngIf="!formDisabled"
              type="button"
              name="cancel-submit-btn"
              class="info-container-form-btn cancel mt-2"
              [disabled]="loading"
              (click)="resetForm(f)"
            >
              <span>Abbrechen</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="info-section">
    <div class="info-section-container">
      <div class="info-header css-row">
        <h2>Verteilung</h2>
        <div class="info-header-options">
          <div class="css-tab-switch">
            <div
              class="css-tab-switch-0"
              (click)="toggleWorkingDetailsView(0)"
              [ngClass]="{
                active: currentWorkingDetailsViewIndex == 0
              }"
            >
              <span>Liste</span>
            </div>
            <div
              class="css-tab-switch-1"
              (click)="toggleWorkingDetailsView(1)"
              [ngClass]="{
                active: currentWorkingDetailsViewIndex == 1
              }"
            >
              <span>Grafik</span>
            </div>
          </div>
        </div>
      </div>
      <div class="info-container">
        <div class="info-container-form ccs-table-content">
          <div
            class="orderitem-working-details-list"
            [ngClass]="{
              show: currentWorkingDetailsViewIndex == 0
            }"
          >
            <div class="orderitem-working-details-list-entry">
              <div>Person</div>
              <div>Zeit</div>
            </div>
            <div
              class="orderitem-working-details-list-entry"
              *ngFor="let user of currentOrderItemUserData"
            >
              <div>{{ user["displayName"] }}</div>
              <div>{{ user["workingTime"] }}h</div>
            </div>
            <div *ngIf="currentOrderItem.bearbeitungszustand == 100">
              <div>
                <i class="fa fa-clock-o mr-1"></i>Noch keine Einträge vorhanden,
                Auftrag wurde noch nicht gestartet
              </div>
              <div></div>
              <div></div>
            </div>
            <div *ngIf="detailsloading">
              <div><div class="spinner-border spinner-border-sm"></div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div
            class="orderitem-working-details-chart"
            [ngClass]="{
              show: currentWorkingDetailsViewIndex == 1
            }"
          >
            <div class="chartContainer">
              <google-chart
                [type]="myChartType"
                [data]="myChartData"
                [columns]="myChartColumns"
                [title]="myChartTitle"
              ></google-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
