import { Component } from "@angular/core";
import { ModalComponent } from "../modal.component";
import { PdfViewComponent } from "src/app/screens/pdf-view/pdf-view.component";

@Component({
  selector: 'app-orderitem-remarks-modal',
  templateUrl: './orderitem-remarks-modal-component.html',
  styleUrls: ['../modal.component.css'],
})
export class OrderItemRemarksModalComponent extends ModalComponent {
  pdfUrl: any

  pdfAvailable(): boolean {
    return this.data.data.pdfLink != null && this.data.data.pdfLink != "";
  }

  openPdf(): void {
    // Get PDF Link form oder service and load the file from the Backend
    let pdfLink = this.orderService.getPdfRestLink(this.data.data.orderItemId);
    this.http.get(pdfLink, { responseType: 'blob' })
    .subscribe((blob: Blob) => {
      this.displayPdf(blob);
    });
  }

  displayPdf(blob: Blob) {
    // Convert into PDF Blob and serve as SafeURL within the client
    const pdfBlob = new Blob([blob], { type: 'application/pdf' });
    this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(pdfBlob));
    console.log(this.pdfUrl)
    this.dialog.open(PdfViewComponent, {
      width: '100%',
      height: '80%',
      data: { pdfUrl: this.pdfUrl },
    });
  }

}
