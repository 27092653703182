<div
  class="bar infodialogbar"
  [ngClass]="
    infodialogbar.state === 0
      ? 'success'
      : infodialogbar.state === 1
      ? 'warning'
      : infodialogbar.state === 2
      ? 'danger'
      : ''
  "
>
  <div class="notifyBannerIconContainer">
    <i class="fas fa-info"></i>
  </div>
  <div class="notifyBannerTextContainer">
    <span class="infodialogtxt">{{ infodialogbar.text }}</span>
  </div>
</div>
<div class="features-boxed home">
  <div class="container">
    <div class="intro small">
      <h3 class="title">Aufträge</h3>
    </div>
    <div class="intro large">
      <h2 class="text-center">KW {{ genDate.woche }}</h2>
      <h4 class="text-muted text-center">
        {{ genDate.datum | date : "dd.MM.YYYY" }}
      </h4>
      <hr />
      <h3 class="text-center title">Aufträge</h3>
    </div>
    <div class="row optionheader">
      <div class="col-7 col-md-6">
        <!-- <app-navigate-button
          *ngIf="isAdmin"
          [btnType]="addOrderBtn.btnType"
          [route]="addOrderBtn.route"
          [title]="addOrderBtn.title"
          [iconType]="addOrderBtn.iconType"
        ></app-navigate-button> -->
      </div>
      <div class="col-5 col-md-6">
        <app-button
          [btnType]="refreshOrderBtn.btnType"
          [title]="refreshOrderBtn.title"
          [iconType]="refreshOrderBtn.iconType"
          [customClasses]="refreshOrderBtn.customClasses"
          [task]="refreshOrderBtn.task"
        ></app-button>
      </div>
    </div>
    <div class="custom-select-box">
      <select class="form-control mt-2" (change)="filterOrderItems($event)">
        <optgroup label="Auftragsart...">
          <option value="all" selected>
            {{ "screens.orderitemOverview.orderItemFilter.all" | translate }}
          </option>
          <option value="onlyActive">
            {{
              "screens.orderitemOverview.orderItemFilter.activeOrders"
                | translate
            }}
          </option>
          <option value="onlyNoUserTime">
            {{
              "screens.orderitemOverview.orderItemFilter.internalOrder"
                | translate
            }}
          </option>
        </optgroup>
      </select>
      <i class="fas fa-caret-down custom-select-box-icon"></i>
    </div>
    <div class="custom-select-box">
      <select
        class="form-control mt-2"
        [(ngModel)]="selectedArea"
        (change)="areaChanged()"
      >
        <optgroup label="Prozess...">
          <option
            *ngFor="let area of areas"
            [ngValue]="area"
            [selected]="area.selected"
          >
            {{ area.name | translate }}
          </option>
        </optgroup>
      </select>
      <i class="fas fa-caret-down custom-select-box-icon"></i>
    </div>
    <div class="custom-select-box">
      <select
        class="form-control mt-2"
        [(ngModel)]="selectedMachine"
        (change)="machineChanged()"
      >
        <optgroup label="Machine...">
          <option
            *ngFor="let machine of machines"
            [ngValue]="machine"
            [selected]="machine.selected"
          >
            {{ machine.name | translate }}
          </option>
        </optgroup>
      </select>
      <i class="fas fa-caret-down custom-select-box-icon"></i>
    </div>
    <div class="custom-select-box">
      <select
        class="form-control mt-2"
        [(ngModel)]="selectedOrderNo"
        (change)="orderItemNoFilterChanged()"
      >
        <optgroup label="Auftrag...">
          <option
            *ngFor="let orderItem of groupedOrderItems"
            [ngValue]="orderItem.value"
            [selected]="orderItem.value == selectedOrderNo"
          >
            {{ orderItem.name | translate }}
          </option>
        </optgroup>
      </select>
      <i class="fas fa-caret-down custom-select-box-icon"></i>
    </div>
    <div class="row justify-content-center features orderItemsContainer">
      <app-orderitem-card
        *ngFor="
          let orderItem of orderItems
            | orderitemFilter : orderItemFilterType
            | ordernoFilter: selectedOrderNo
            | sort : direction : column : type
        "
        (orderItemEvent)="orderItemEvent($event)"
        [orderItem]="orderItem"
        [genDate]="genDate"
        style="width: 100%; max-width: 350px"
      ></app-orderitem-card>
      <div *ngIf="orderItems.length == 0 && !loading" class="p-5 text-center">
        <i class="fa fa-clock-o mr-1"></i>
        Noch keine Aufträge vorhanden.
        <div *ngIf="isAdmin">
          Jetzt Auftrag
          <button
            class="btn btn-custom btn-outline mx-2"
            [routerLink]="['/addOrder']"
          >
            <span>hinzufügen</span>
          </button>
        </div>
      </div>
      <div
        *ngIf="loading"
        class="spinner-border spinner-border-sm mx-auto loadingspinner mt-5"
      ></div>
    </div>
  </div>
</div>
