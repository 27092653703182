<mat-dialog-content>
  <label for="remarks">Zusatzinfos:</label>
  <textarea
    class="form-control"
    id="remarks"
    name="remarks"
    cols="50"
    rows="10"
    disabled
  >{{message}}</textarea>
  <app-button [title]="'PDF'" (click)="openPdf()" *ngIf="pdfAvailable()"></app-button>
</mat-dialog-content>
<mat-dialog-actions class="custom-mat-dialog-actions">
  <div class="modal-dialog-footer" *ngIf="!taskLoading">
    <app-button
      *ngFor="let button of buttons"
      [btnType]="button.type"
      [dialogRef]="dialogRef"
      [task]="button.task"
      [title]="button.text"
    ></app-button>
  </div>
  <div class="modal-dialog-footer" *ngIf="taskLoading">
    <button class="btn btn-custom btn-outline">
      <div class="spinner-border spinner-border-sm"></div>
    </button>
  </div>
</mat-dialog-actions>
