export enum InfoDialog {
  ASSIGNSTART,
  ASSIGNSTOP,
  ORDERITEMSTART,
  ORDERITEMPAUSED,
  ORDERITEMSTOPPED,
  ORDERITEMCHECKED,
  ORDERITEMCREATED,
  ORDERITEMSTARTUSERLESS,
  ORDERITEMPAUSEDUSERLESS,
  DEFAULT,
}

export enum InfoDialogText {
  ASSIGNSTART = 'Arbeit an Auftrag gestartet',
  ASSIGNSTOP = 'Arbeit an Auftrag beendet',
  ORDERITEMSTART = 'Arbeit gestartet',
  ORDERITEMPAUSED = 'Arbeit pausiert',
  ORDERITEMSTOPPED = 'Auftrag beendet',
  ORDERITEMCHECKED = 'Auftrag entfernt',
  ORDERITEMCREATED = 'Auftrag angelegt',
  ORDERITEMSTARTUSERLESS = 'Mannloser Lauf gestartet',
  ORDERITEMPAUSEDUSERLESS = 'Mannloser Lauf beendet',
  DEFAULT = '',
}
export enum InfoDialogState {
  SUCCESS = 0,
  WARNING = 1,
  DANGER = 2,
  DEFAULT = -1,
}

export function buildInfoDialog(textEnum: InfoDialog, dialog: any) {
  switch (textEnum) {
    case InfoDialog.ASSIGNSTART:
      dialog.text = InfoDialogText.ASSIGNSTART;
      dialog.state = InfoDialogState.SUCCESS;
      break;
    case InfoDialog.ASSIGNSTOP:
      dialog.text = InfoDialogText.ASSIGNSTOP;
      dialog.state = InfoDialogState.DANGER;
      break;
    case InfoDialog.ORDERITEMSTART:
      dialog.text = InfoDialogText.ORDERITEMSTART;
      dialog.state = InfoDialogState.SUCCESS;
      break;
    case InfoDialog.ORDERITEMPAUSED:
      dialog.text = InfoDialogText.ORDERITEMPAUSED;
      dialog.state = InfoDialogState.WARNING;
      break;
    case InfoDialog.ORDERITEMSTARTUSERLESS:
      dialog.text = InfoDialogText.ORDERITEMSTARTUSERLESS;
      dialog.state = InfoDialogState.SUCCESS;
      break;
    case InfoDialog.ORDERITEMPAUSEDUSERLESS:
      dialog.text = InfoDialogText.ORDERITEMPAUSEDUSERLESS;
      dialog.state = InfoDialogState.WARNING;
      break;
    case InfoDialog.ORDERITEMSTOPPED:
      dialog.text = InfoDialogText.ORDERITEMSTOPPED;
      dialog.state = InfoDialogState.DANGER;
      break;
    case InfoDialog.ORDERITEMCHECKED:
      dialog.text = InfoDialogText.ORDERITEMCHECKED;
      dialog.state = InfoDialogState.DANGER;
      break;
    case InfoDialog.ORDERITEMCREATED:
      dialog.text = InfoDialogText.ORDERITEMCREATED;
      dialog.state = InfoDialogState.SUCCESS;
      break;
    default:
      dialog.text = InfoDialogText.DEFAULT;
      dialog.state = InfoDialogState.DEFAULT;
      break;
  }

  setTimeout(() => {
    dialog.text = InfoDialogText.DEFAULT;
    dialog.state = InfoDialogState.DEFAULT;
  }, 3000);
}
