import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ButtonType } from '../../enums/button-type.enum';
import { ClientMessages } from '../../enums/client-messages.enum';
import { Correctionmessage } from '../../enums/correctionmessage.enum';
import { ModalButtonGenerator } from '../../modal/buttons/impl/modal-button-generator';
import { ModalComponent } from '../../modal/modal.component';
import { OrderService } from '../../services/order.service';
import { SocketioService } from '../../services/socketio.service';
import { CancelTask } from '../../tasks/impl/cancel.task';
import { DeleteOrderitemTimeTask } from '../../tasks/impl/delete-orderitem-time.task';
import { UpdateOrderitemTimeTask } from '../../tasks/impl/update-orderitem-time.task';
import { OnErrorTask } from '../../tasks/on-error-task';
import { OnSuccessTask } from '../../tasks/on-success-task';

@Component({
  selector: 'app-correction',
  templateUrl: './correction.component.html',
  styleUrls: ['./correction.component.css'],
})
export class CorrectionComponent implements OnInit, OnSuccessTask, OnErrorTask {
  orderItemTimes: any[] = [];
  orderItemId!: string | null;
  orderItemNo!: string | null;
  loading = true;

  private currentSelectedItem:
    | {
        oldDateFrom: any[];
        oldDateTo: any[];
        dateFrom: HTMLInputElement;
        timeFrom: HTMLInputElement;
        dateTo: HTMLInputElement;
        timeTo: HTMLInputElement;
      }
    | undefined;

  REALLYDELETE = 'Wollen Sie diesen Eintrag wirklich löschen?';
  REALLYCHANGE = (
    oldFrom: string,
    oldTo: string,
    newFrom: string,
    newTo: string
  ): string => {
    var result = 'Eintrag wirklich bearbeiten?\n';
    result =
      result + `<p>Alter Eintrag:<br>${oldFrom}<br>${oldTo}<br><br>Neuer Eintrag:<br>${newFrom}<br>${newTo}<p>`;
    return result;
  };
  correctionMessage: Correctionmessage | undefined;

  getTwoDigits = (num: number): string => {
    if (num > 9) {
      return String(num);
    }
    return String('0' + num);
  };

  constructor(
    private route: ActivatedRoute,
    private orderService: OrderService,
    private socketService: SocketioService,
    private dialog: MatDialog
  ) {}

  onSuccess(data?: any): void {
    if (data) {
      console.log(data);
    }
    this.getTimes();
  }

  onError(clientMessage?: any): void {
    if (clientMessage) {
      let okButton = ModalButtonGenerator.createButton('Ok', new CancelTask(), ButtonType.PRIMARY);

      let message = `<p><span class="font-weight-bold">${ClientMessages.INTERNAL_ERROR}</span><br><br>${clientMessage}<p>`;

      const dialogRef = this.dialog.open(ModalComponent, {
        data: {
          message,
          buttons: [okButton],
        },
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        console.log('The dialog was closed');
      });
    }

    this.resetValuesLocally();
    this.correctionMessage = undefined;
  }

  extractDateFormats(date: string | null): any[] {
    var result = [];
    if (date !== null) {
      const dateObj = new Date(date);
      result.push(
        `${dateObj.getFullYear()}-${this.getTwoDigits(
          dateObj.getMonth() + 1
        )}-${this.getTwoDigits(dateObj.getDate())}`
      );
      result.push(
        `${this.getTwoDigits(dateObj.getHours())}:${this.getTwoDigits(
          dateObj.getMinutes()
        )}`
      );
    }
    return result;
  }

  getTimes() {
    this.orderItemId = this.route.snapshot.paramMap.get('id');
    this.orderItemNo = this.route.snapshot.paramMap.get('orderitemno');

    this.loading = true;
    this.orderService
      .getTimes(this.orderItemId == null ? '' : this.orderItemId)
      .subscribe((orderItemTimes: any[]) => {
        this.loading = false;
        console.log("order item times", orderItemTimes);
        this.orderItemTimes = orderItemTimes;
        this.orderItemTimes.forEach((orderItemTime) => {
          if (orderItemTime.dateFrom !== null) {
            orderItemTime.oldDateFrom = orderItemTime.dateFrom;
            const dateFrom = new Date(orderItemTime.dateFrom);
            orderItemTime.dateFrom = `${dateFrom.getFullYear()}-${this.getTwoDigits(
              dateFrom.getMonth() + 1
            )}-${this.getTwoDigits(dateFrom.getDate())}`;
            orderItemTime.timeFrom = `${this.getTwoDigits(
              dateFrom.getHours()
            )}:${this.getTwoDigits(dateFrom.getMinutes())}`;
          }

          if (orderItemTime.dateTo !== null) {
            orderItemTime.oldDateTo = orderItemTime.dateTo;
            const dateTo = new Date(orderItemTime.dateTo);
            orderItemTime.dateTo = `${dateTo.getFullYear()}-${this.getTwoDigits(
              dateTo.getMonth() + 1
            )}-${this.getTwoDigits(dateTo.getDate())}`;
            orderItemTime.timeTo = `${this.getTwoDigits(
              dateTo.getHours()
            )}:${this.getTwoDigits(dateTo.getMinutes())}`;
          }

          this.enableOrderItemEdit(orderItemTime, false);
        });
      });

    this.socketService.loadOrderItems();
  }

  ngOnInit(): void {
    this.getTimes();
  }

  resetValuesLocally() {
    if (this.currentSelectedItem) {
      this.currentSelectedItem.dateFrom.value =
        this.currentSelectedItem.oldDateFrom[0];
      this.currentSelectedItem.timeFrom.value =
        this.currentSelectedItem.oldDateFrom[1];
      this.currentSelectedItem.dateTo.value =
        this.currentSelectedItem.oldDateTo[0];
      this.currentSelectedItem.timeTo.value =
        this.currentSelectedItem.oldDateTo[1];
    }
  }

  enableOrderItemEdit(orderItemTime: any, enable: boolean) {
    orderItemTime.disabled = !enable;
  }

  saveChanges(
    orderItemTime: any,
    dateFrom: HTMLInputElement,
    timeFrom: HTMLInputElement,
    dateTo: HTMLInputElement,
    timeTo: HTMLInputElement
  ) {
    this.enableOrderItemEdit(orderItemTime, false);

    // old values
    const oldDateFrom = this.extractDateFormats(orderItemTime.oldDateFrom);
    const oldDateTo = this.extractDateFormats(orderItemTime.oldDateTo);

    // new values
    const newDateFrom = `${dateFrom.value},${timeFrom.value}`;
    const newDateTo = `${dateTo.value},${timeTo.value}`;

    this.currentSelectedItem = {
      oldDateFrom,
      oldDateTo,
      dateFrom,
      timeFrom,
      dateTo,
      timeTo,
    };

    let updateOrderitemTimeTask = new UpdateOrderitemTimeTask();
    updateOrderitemTimeTask.setValues(
      orderItemTime,
      this.orderService,
      dateFrom,
      timeFrom,
      dateTo,
      timeTo,
      this,
      this
    );
    let okButton = ModalButtonGenerator.createOkButton(updateOrderitemTimeTask);
    let cancelButton = ModalButtonGenerator.createCancelButton(this);

    const dialogRef = this.dialog.open(ModalComponent, {
      data: {
        message: this.REALLYCHANGE(
          oldDateFrom.toString(),
          oldDateTo.toString(),
          newDateFrom,
          newDateTo
        ),
        buttons: [okButton, cancelButton],
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      console.log('The dialog was closed');
    });
  }

  deleteOrderItemTime(orderItemTime: any) {
    let deleteOrderitemTimeTask = new DeleteOrderitemTimeTask();
    deleteOrderitemTimeTask.setValues(
      orderItemTime,
      this.orderService,
      this,
      this
    );
    let okButton = ModalButtonGenerator.createOkButton(deleteOrderitemTimeTask);
    let cancelButton = ModalButtonGenerator.createCancelButton();

    const dialogRef = this.dialog.open(ModalComponent, {
      data: {
        message: this.REALLYDELETE,
        buttons: [okButton, cancelButton],
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      console.log('The dialog was closed');
    });
  }
}
