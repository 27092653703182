import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value: any[], input: any): any[] {
    if (input) {
      // 'PLA' = Personenlose Auftraege
      if (input === 'PLA') {
        return value.filter((val: any) => val.noUserTime === true);
      }
      return value.filter((val: any) => val.kunde.indexOf(input) >= 0 || val.auftragsnr.indexOf(input) >= 0);
    } else {
      return value;
    }
  }

}
