import { Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pdf-view',
  templateUrl: './pdf-view.component.html',
  styleUrls: ['./pdf-view.component.css']
})
export class PdfViewComponent {
  zoom: number = 1.0;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  zoomIn(): void {
    console.log('test')
    this.zoom += 0.1; // Increase the zoom level by 0.1
  }

  zoomOut(): void {
    this.zoom -= 0.1; // Decrease the zoom level by 0.1
  }
}
