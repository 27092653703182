import { Component, OnInit } from '@angular/core';
import { OrderService } from '../../services/order.service';
import { TokenStorageService } from '../../services/token-storage.service';

interface Month {
  index: Number;
  text: String;
  isActive: boolean;
  isLoading: boolean;
  orderitems: {
    id: string;
    text: string;
    time: string;
    timeWTZ?: string;
  }[];
  summarized?: Number;
}

interface WorkingTime {
  orderItemNo: string;
  time: string;
}

@Component({
  selector: 'app-time-tracking-evaluation',
  templateUrl: './time-tracking-evaluation.component.html',
  styleUrls: ['./time-tracking-evaluation.component.css'],
})
export class TimeTrackingEvaluationComponent implements OnInit {
  private monthsTitles = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ];

  getYears(): { label: string }[] {
    let currentYear = new Date().getFullYear();
    return [
      {
        label: currentYear.toString(),
      },
    ];
  }

  getIntervalls(): { label: string }[] {
    return [
      {
        label: 'Heute',
      },
    ];
  }

  getUsers(): { key: number; label: string }[] {
    return [
      {
        key: 0,
        label: this.user.vorname + ' ' + this.user.nachname,
      },
      {
        key: 1,
        label: 'Alle Mitarbeiter',
      },
    ];
  }

  selectedYear: string = '';
  selectedIntervall: string = '';

  monthsList: Month[] = [];

  workingTimes: WorkingTime[] = [];
  sumWorkingTimes: string = '0';

  user: any;
  isAdmin = false;
  selectedUser: string = '0';

  constructor(
    private orderService: OrderService,
    private tokenStorageService: TokenStorageService
  ) {}

  ngOnInit(): void {
    this.selectedYear = this.getYears()[0].label;
    this.selectedIntervall = this.getIntervalls()[0].label;
    this.buildMonthList();
    this.loadWorkingTimes();
    // this.extractDatas(this.testJSON);

    this.user = this.tokenStorageService.getUser();
    this.isAdmin = this.user.roles.includes('ROLE_ADMIN');
  }

  extractDatas(datas: Month[]) {
    this.monthsList = datas;
  }

  toggleMonthState(month: Month) {
    if (month.isActive) {
      month.isActive = false;
    } else {
      month.isActive = true;
      month.isLoading = true;

      if (this.selectedUser == '0') {
        console.log('selected is 0');
        this.orderService
          .getUserResults(String(month.index), this.selectedYear)
          .subscribe((results: any) => {
            month.isLoading = false;
            if (results.error) {
              console.log('error');
              return;
            }
            month.orderitems = results.orderitems;
            month.summarized = results.summarized;
          });
      } else {
        console.log('selected is 1');
        this.orderService
          .getAllUserResults(String(month.index), this.selectedYear)
          .subscribe((results: any) => {
            month.isLoading = false;
            if (results.error) {
              console.log('error');
              return;
            }
            month.orderitems = results.orderitems;
          });
      }
    }
  }

  buildMonthList() {
    this.monthsList = [];
    let currentMonth = new Date().getMonth();

    for (let i = 0; i < 3; i++) {
      let calculatedMonthIndex = currentMonth - i;
      if (calculatedMonthIndex < 0) {
        calculatedMonthIndex = 12 + calculatedMonthIndex;
      }

      let monthObj: Month = {
        index: calculatedMonthIndex,
        text: this.monthsTitles[calculatedMonthIndex],
        isActive: false,
        isLoading: false,
        orderitems: [],
        summarized: 0,
      };

      this.monthsList.push(monthObj);
    }
  }

  changeYear(event: any) {
    let value: string = event.target.value;
    this.selectedYear = value;
    this.buildMonthList();
  }

  loadWorkingTimes() {
    this.orderService.getWorkingTimes().subscribe((workingTimes: any) => {
      this.workingTimes = workingTimes.entries;
      this.sumWorkingTimes = workingTimes.sum;
    });
  }

  changeUser(event: any) {
    let value: string = event.target.value;
    this.selectedUser = value;
    console.log(this.selectedUser);
    this.buildMonthList();
  }
}
