<div
  class="box orderitembox orderitem-card"
  [ngClass]="{
    'orderitem-raw': orderItem.bearbeitungszustand == '100',
    'orderitem-success': orderItem.bearbeitungszustand == '200',
    'orderitem-paused': orderItem.bearbeitungszustand == '300',
    'orderitem-done': orderItem.bearbeitungszustand == '400',
    'orderitem-assigned': orderItem.assigned
  }"
  id="{{ orderItem.id }}"
>
  <div
    *ngIf="isCritical() && orderItem.bearbeitungszustand != '400'"
    class="banner critical"
  >
    <span>Zeitkritisch! {{ getWarningText(orderItem) }}</span>
  </div>
  <h3 class="customerLabel">
    <strong>{{ orderItem.auftragsnr }}</strong>
    <i
      *ngIf="orderItem.noUserTime == true"
      class="fa fa-user-slash css-orderitem-no-user-time-icon"
    ></i>
  </h3>
  <span
    [ngClass]="{
      'box-critical': isCritical() && orderItem.bearbeitungszustand != '400'
    }"
    >{{ "screens.orderItemOverviewHistory.forecastEndTS" | translate }}:
    {{ orderItem.forecastEndDate | date : "dd.MM.YYYY" }}</span
  >
  <span
    >{{ "screens.orderItemOverviewHistory.plannedTS" | translate }}:
    {{ orderItem.geplanterTermin | date : "dd.MM.YYYY" }}</span
  >
  <h4>{{ orderItem.machineName }}</h4>
  <span
    >{{ orderItem.asfId }} / {{orderItem.identifier}}</span
  >
  <span *ngIf="orderItem.kunde">{{ orderItem.kunde }}</span>
  <span *ngIf="orderItem.anmerkungen" lineBreakCutoffEllipsis>{{ orderItem.anmerkungen }}</span>
  <h4 *ngIf="orderItem.bearbeitungszustand > '100'">
    {{ orderItem.arbeitszeit }}Std
  </h4>
  <i *ngIf="orderItem.assigned" class="fa fa-wrench assignsymbol blue"></i>
  <span class="blue">{{ orderItem.assignees }}</span>
  <div class="orderitem-card-action-container">
    <div class="orderitem-card-sps-action-container">
      <button
        [hidden]="
          orderItem.bearbeitungszustand == '200' &&
          (orderItem.assigned)
        "
        (press)="openRunUserlessDialog()"
        type="button"
        name="controlBtn-play-{{ orderItem.id }}"
        class="orderitem-card-action"
        [ngClass]="
          orderItem.bearbeitungszustand == 200 ? 'btnDark' : 'btnLight'
        "
        (click)="startOrderItem()"
      >
        <i class="fa fa-play"></i>
      </button>
      <button
        [hidden]="
          orderItem.bearbeitungszustand != '200' ||
          (!orderItem.assigned)
        "
        type="button"
        name="controlBtn-pause-{{ orderItem.id }}"
        class="orderitem-card-action"
        [ngClass]="
          orderItem.bearbeitungszustand == 300 ? 'btnDark' : 'btnLight'
        "
        (click)="pauseOrderItem()"
      >
        <i class="fa fa-pause"></i>
      </button>
      <button
        [hidden]="orderItem.bearbeitungszustand != '300'"
        type="button"
        name="controlBtn-stop-{{ orderItem.id }}"
        class="orderitem-card-action"
        [ngClass]="
          orderItem.bearbeitungszustand == 400 ? 'btnDark' : 'btnLight'
        "
        (click)="stopOrderItem()"
      >
        <i class="fa fa-stop"></i>
      </button>
      <button
        [hidden]="orderItem.bearbeitungszustand != '400'"
        type="button"
        name="controlBtn-stop-{{ orderItem.id }}"
        class="orderitem-card-action"
        [ngClass]="
          orderItem.bearbeitungszustand == 400 ? 'btnDark' : 'btnLight'
        "
        (click)="checkOrderItem()"
      >
        <i class="fa fa-times"></i>
      </button>
      <button class="orderitem-card-action" (click)="openRemarksDialog()">
        <i class="fa fa-info"></i>
      </button>
    </div>
    <button
      [hidden]="orderItem.bearbeitungszustand == '200' && orderItem.running_userless"
      class="orderitem-card-action"
      (click)="startOrderItemUserless()"
    >
      <i class="fa fa-play mr-2"></i>
      Maschine
    </button>
    <button
      [hidden]="!orderItem.running_userless"
      class="orderitem-card-action"
      (click)="pauseOrderItemUserless()"
    >
      <i class="fa fa-pause mr-2"></i>
      Maschine
  </button>
  </div>
</div>
