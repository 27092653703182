import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ButtonIconType } from 'src/app/enums/button-icon-type.enum';
import { ButtonType } from 'src/app/enums/button-type.enum';
import { ModalComponent } from 'src/app/modal/modal.component';
import { Task } from 'src/app/tasks/task';

export interface IButton {
  btnType: ButtonType;
  dialogRef?: MatDialogRef<ModalComponent>;
  task?: Task;
  title: string;
  cssStyle?: string;
  customClasses?: string;
  iconType?: ButtonIconType;
}

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css'],
})
export class ButtonComponent implements OnInit, IButton {
  @Input()
  btnType: ButtonType = ButtonType.PRIMARY;

  @Input()
  dialogRef?: MatDialogRef<ModalComponent>;

  @Input()
  task?: Task;

  @Input()
  title: string = '';

  @Input()
  iconType?: ButtonIconType;

  @Input()
  cssStyle?: any = 'margin: 0.5em 0.25em;';

  @Input()
  customClasses?: string = '';

  taskLoading: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  async executeTask() {
    await this.task?.execute(this.dialogRef, this);
  }

  enableTaskLoading(loading: boolean) {
    this.taskLoading = loading;
  }

  getBtnClass() {
    let clazzNames = 'btn btn-custom';

    switch (this.btnType) {
      case ButtonType.PRIMARY:
        break;
      case ButtonType.SECONDARY:
        clazzNames += ' btn-outline';
        break;
      default:
        break;
    }

    clazzNames += ` ${this.customClasses}`;

    return clazzNames;
  }
}
