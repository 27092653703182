import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "ordernoFilter",
})
export class OrdernoFilterPipe implements PipeTransform {
  transform(value: any[], input: any): any[] {
    if (input) {
      return value.filter((val: any) => val["auftragsnr"] == input);
    }
    return value;
  }
}
