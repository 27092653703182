import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';

@Directive({
  selector: '[appSortParams]',
})
export class SortParamsDirective {
  @Output() param: EventEmitter<any> = new EventEmitter();

  constructor(private element: ElementRef) {}

  @HostListener('click') onClickIcon() {
    this.selectSort(this.element.nativeElement.id);
  }

  @HostListener('change') onChangeSelect() {
    this.selectSort(this.element.nativeElement.value);
  }

  selectSort(id: string) {
    switch (id) {
      case 'orderItemNoAsc':
        this.param.emit({ dir: 'asc', col: 'auftragsnr', typ: 'string' });
        break;
      case 'plannedDateAsc':
        this.param.emit({ dir: 'asc', col: 'geplanterTermin', typ: 'date' });
        break;
      case 'customerAsc':
        this.param.emit({ dir: 'asc', col: 'kunde', typ: 'string' });
        break;
      case 'orderItemNoDesc':
        this.param.emit({ dir: 'desc', col: 'auftragsnr', typ: 'string' });
        break;
      case 'plannedDateDesc':
        this.param.emit({ dir: 'desc', col: 'geplanterTermin', typ: 'date' });
        break;
      case 'customerDesc':
        this.param.emit({ dir: 'desc', col: 'kunde', typ: 'string' });
        break;
    }
  }
}
