<button
  class="btn btn-custom"
  [ngClass]="getBtnClass()"
  (click)="executeTask()"
  tabindex="1"
  [disabled]="taskLoading"
  [style]="cssStyle"
>
  <i *ngIf="iconType" [ngClass]="iconType" class="mr-2"></i>
  <span>{{ title | translate }}</span>
  <div *ngIf="taskLoading" class="ml-2" class="spinner-border spinner-border-sm"></div>
</button>
