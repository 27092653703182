import { MatDialogRef } from '@angular/material/dialog';
import { Correctionmessage } from 'src/app/enums/correctionmessage.enum';
import { ModalComponent } from 'src/app/modal/modal.component';
import { OrderItem } from 'src/app/screens/order-item-overview/order-item-overview.component';
import { OrderService } from 'src/app/services/order.service';
import { OnErrorTask } from 'src/app/tasks/on-error-task';
import { OnSuccessTask } from 'src/app/tasks/on-success-task';
import { Task } from '../task';

export class UpdateOrderitemTimeTask implements Task {
  private orderitemTime: any | null = null;
  private orderService: OrderService | null = null;
  private dateFrom!: HTMLInputElement;
  private timeFrom!: HTMLInputElement;
  private dateTo!: HTMLInputElement;
  private timeTo!: HTMLInputElement;
  private delegateSuccess: OnSuccessTask | undefined;
  private delegateError: OnErrorTask | undefined;

  private correctionMessage: Correctionmessage | undefined;

  async execute(dialogRef?: MatDialogRef<ModalComponent>): Promise<void> {
    try {
      if (this.orderitemTime != null && this.orderService != null) {
        this.orderitemTime.disabled = true;

        if (
          this.isValidCorrection(
            this.dateFrom.value,
            this.timeFrom.value,
            this.dateTo.value,
            this.timeTo.value
          )
        ) {
          let result = await this.orderService
            .updateTime(
              this.orderitemTime.id,
              this.dateFrom.value,
              this.timeFrom.value,
              this.dateTo.value,
              this.timeTo.value
            )
            .toPromise();

          dialogRef?.componentInstance?.enableTaskLoading(false);
          dialogRef?.close(true);

          this.delegateSuccess?.onSuccess(result);

          console.log('Executed ' + UpdateOrderitemTimeTask.name);
          return;
        }
        this.handleError(
          this.correctionMessage ? this.correctionMessage : '',
          dialogRef
        );
      }
    } catch (error) {
      this.handleError(
        this.correctionMessage ? this.correctionMessage : '',
        dialogRef,
        error
      );
    }
  }

  handleError(
    clientMsg: string,
    dialogRef?: MatDialogRef<ModalComponent>,
    error?: any
  ) {
    dialogRef?.componentInstance?.enableTaskLoading(false);
    dialogRef?.close(true);

    this.delegateError?.onError(clientMsg);
    if (error) {
      console.error(error);
    }
  }

  setValues(
    orderItem: OrderItem,
    orderService: OrderService,
    dateFrom: HTMLInputElement,
    timeFrom: HTMLInputElement,
    dateTo: HTMLInputElement,
    timeTo: HTMLInputElement,
    delegateSuccess: OnSuccessTask,
    delegateError: OnErrorTask
  ) {
    this.orderitemTime = orderItem;
    this.orderService = orderService;
    this.dateFrom = dateFrom;
    this.timeFrom = timeFrom;
    this.dateTo = dateTo;
    this.timeTo = timeTo;
    this.delegateSuccess = delegateSuccess;
    this.delegateError = delegateError;
  }

  isValidCorrection(
    beginDate: string,
    beginTime: string,
    endDate: string,
    endTime: string
  ): boolean {
    try {
      const beginDateNumber = this.extractStringDateInput(beginDate);
      const beginTimeNumber = this.extractStringTimeInput(beginTime);
      const endDateNumber = this.extractStringDateInput(endDate);
      const endTimeNumber = this.extractStringTimeInput(endTime);
      if (endDateNumber > beginDateNumber) {
        return true;
      }
      if (endDateNumber == beginDateNumber) {
        if (endTimeNumber > beginTimeNumber) {
          return true;
        }
      }
    } catch (error) {
      this.correctionMessage = Correctionmessage.DEFAULT;
      return false;
    }
    this.correctionMessage = Correctionmessage.BEGINBIGGERTHANEND;
    return false;
  }

  extractStringDateInput(value: string): Number {
    var valueSplitted = value.split('-');
    if (valueSplitted.length !== 3) {
      return 0;
    }
    var valueNumber = `${valueSplitted[0]}${valueSplitted[1]}${valueSplitted[2]}`;
    return parseInt(valueNumber);
  }

  extractStringTimeInput(value: string): Number {
    var valueSplitted = value.split(':');
    if (valueSplitted.length !== 2) {
      return 0;
    }
    var valueNumber = `${valueSplitted[0]}${valueSplitted[1]}`;
    return parseInt(valueNumber);
  }
}
