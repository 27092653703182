import { Component, OnInit } from '@angular/core';
import { MultiplierService } from '../../services/multiplier.service';

export interface Multiplier {
  id: any;
  dayOfWeek: string;
  startTimeTS: string;
  endTimeTS: string;
  factor: number;
}

export class Multiplier {
  constructor(
    dayOfWeek: string,
    startTimeTS: string,
    endTimeTS: string,
    factor: number
  ) {
    this.dayOfWeek = dayOfWeek;
    this.startTimeTS = startTimeTS;
    this.endTimeTS = endTimeTS;
    this.factor = factor;
  }

  dayOfWeek!: string;
  startTimeTS!: string;
  endTimeTS!: string;
  factor!: number;
}

export interface TimeZone {
  id: string;
  Multipliers: Multiplier[];
  addingForm: any;
  multiplier: Number;
}

@Component({
  selector: 'app-multiplier',
  templateUrl: './multiplier.component.html',
  styleUrls: ['./multiplier.component.css'],
})
export class MultiplierComponent implements OnInit {
  timezones: any[] = [];

  daysOfWeek = [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
  ];

  constructor(private multiplierService: MultiplierService) {}

  ngOnInit(): void {
    this.getTimezones();
  }

  getTimezones(): void {
    this.multiplierService.getTimezones().subscribe((timezones: any[]) => {
      this.timezones = timezones;
      this.timezones.forEach((timezone: TimeZone) => {
        timezone.Multipliers.forEach((multiplier: Multiplier) => {
          let datefrom = new Date(multiplier.startTimeTS);
          let fromHours =
            datefrom.getHours() > 9
              ? datefrom.getHours()
              : '0' + datefrom.getHours();
          let fromMinutes =
            datefrom.getMinutes() > 9
              ? datefrom.getMinutes()
              : '0' + datefrom.getMinutes();
          multiplier.startTimeTS = `${fromHours}:${fromMinutes}`;

          let dateto = new Date(multiplier.endTimeTS);
          let toHours =
            dateto.getHours() > 9 ? dateto.getHours() : '0' + dateto.getHours();
          let toMinutes =
            dateto.getMinutes() > 9
              ? dateto.getMinutes()
              : '0' + dateto.getMinutes();
          multiplier.endTimeTS = `${toHours}:${toMinutes}`;

          multiplier.dayOfWeek = this.daysOfWeek[
            parseInt(multiplier.dayOfWeek)
          ];
        });
      });
    });
  }

  addTimezone(): void {
    var multiplier = prompt('Bitte Multiplikator eingeben.\nz.B. 0,5', '');
    if (multiplier) {
      let multiplierNum: Number = Number(multiplier.replace(',', '.'));
      this.multiplierService
        .addTimezone(multiplierNum)
        .subscribe((timezone) => {
          this.getTimezones();
        });
    }
  }

  addMultiplier(
    selectedDayOfweek: HTMLSelectElement,
    timeFrom: HTMLInputElement,
    timeto: HTMLInputElement,
    multiplier: Number,
    timezone: string
  ) {
    console.log(
      selectedDayOfweek.value + timeFrom.value + timeto.value + multiplier,
      timezone
    );
    var multiplierObject = new Multiplier(
      selectedDayOfweek.value,
      timeFrom.value,
      timeto.value,
      Number(multiplier)
    );
    this.multiplierService
      .addMultiplier(multiplierObject, timezone)
      .subscribe((data: any) => {
        this.getTimezones();
      });
  }

  removeTimezone(id: any) {
    let confirmation = confirm('Zeitzone wirklich löschen?');
    if (confirmation) {
      console.log(JSON.stringify(id));
      this.multiplierService
        .removeTimezone(String(id))
        .subscribe((data: any) => {
          this.getTimezones();
        });
    }
  }

  updateTimezone(id: string) {
    var multiplier = prompt('Bitte Multiplikator eingeben.\nz.B. 0,5', '');
    if (multiplier) {
      let multiplierNum: Number = Number(multiplier.replace(',', '.'));
      this.multiplierService
        .updateTimezone(id, multiplierNum)
        .subscribe((timezone) => {
          this.getTimezones();
        });
    }
  }

  removeMultiplier(id: string) {
    let confirmation = confirm('Eintrag wirklich löschen?');
    if (confirmation) {
      console.log(JSON.stringify(id));
      this.multiplierService
        .removeMultiplier(String(id))
        .subscribe((data: any) => {
          this.getTimezones();
        });
    }
  }
}
