import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderitemFilter',
})
export class OrderitemFilterPipe implements PipeTransform {
  transform(value: any[], input: any): any[] {
    if (input) {
      if (input == 'default') {
        return value.filter((val: any) => val.bearbeitungszustand < 500);
      } else if (input == 'onlyActive') {
        return value.filter((val: any) => val.bearbeitungszustand == 200);
      } else if (input == 'onlyNoUserTime') {
        return value.filter((val: any) => val.noUserTime === true);
      }
    }
    return value;
  }
}
