import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ButtonType } from '../../enums/button-type.enum';
import { ClientMessages } from '../../enums/client-messages.enum';
import { ModalButtonGenerator } from '../../modal/buttons/impl/modal-button-generator';
import { ModalComponent } from '../../modal/modal.component';
import { AttributeManagerService } from '../../services/attribute-manager.service';
import { OrderService } from '../../services/order.service';
import { TokenStorageService } from '../../services/token-storage.service';
import { CancelTask } from '../../tasks/impl/cancel.task';
import { RemoveOrderitemTask } from '../../tasks/impl/remove-orderitem.task';
import { OnErrorTask } from '../../tasks/on-error-task';
import { OnSuccessTask } from '../../tasks/on-success-task';
import { OrderItem } from '../order-item-overview/order-item-overview.component';

@Component({
  selector: 'app-order-item-history-overview',
  templateUrl: './order-item-history-overview.component.html',
  styleUrls: ['./order-item-history-overview.component.css'],
})
export class OrderItemHistoryOverviewComponent implements OnInit, OnSuccessTask, OnErrorTask {
  sidebarOpen!: boolean;
  orderItems: any[] = [];
  orderItemDummy: any = {
    id: '',
    kunde: '',
    auftragsnr: '',
    bestellnr: '',
    arbeitszeit: '',
    geplanterTermin: '',
    bearbeitungszustand: '',
    overtime: '',
    assigned: null,
  };
  currentOrderItem = this.orderItemDummy;
  currentOrderItemUserData?: any[];

  direction: string = 'asc';
  column: string = 'auftragsnr';
  type: string = 'string';

  orderDescription = '';

  currentUser: any;
  isAdmin = false;
  resultsloading = false;

  constructor(
    private attributeHandler: AttributeManagerService,
    private orderService: OrderService,
    private tokenStorageService: TokenStorageService,
    private dialog: MatDialog
  ) {}

  onSuccess(data?: any): void {
    if (data) {
      this.orderItems = data;
    }
  }
  onError(clientMessage?: any): void {
    if (clientMessage) {
      console.warn(clientMessage);

      let okButton = ModalButtonGenerator.createButton('Ok', new CancelTask(), ButtonType.PRIMARY);
      let message =  `<p><span class="font-weight-bold">${ClientMessages.INTERNAL_SERVER_ERROR}</span><br><br>${ClientMessages.PLEASE_CONTACT_ADMIN}<br><span class="font-italic">(Message: ${clientMessage})</span><p>`;

      const dialogRef = this.dialog.open(ModalComponent, {
        data: {
          message,
          buttons: [okButton],
          customMessage: true
        },
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        console.log('The dialog was closed');
      });
    }
  }

  ngOnInit(): void {
    this.resultsloading = true;

    this.attributeHandler.orderOverlayOpen.subscribe((data: boolean) => {
      this.sidebarOpen = data;
    });

    this.orderService.getOrders().subscribe((data: any) => {
      this.orderItems = data;
      this.resultsloading = false;
    });

    this.tokenStorageService.currentUserObs.subscribe((user: any) => {
      this.currentUser = JSON.parse(user);
      this.isAdmin = this.currentUser.roles.includes('ROLE_ADMIN');
    });
  }

  setSortParams(param: any) {
    this.direction = param.dir;
    this.column = param.col;
    this.type = param.typ;
  }

  removeOrderItem(orderItem: OrderItem) {
    let updateOrderitemTimeTask = new RemoveOrderitemTask();
    updateOrderitemTimeTask.setValues(orderItem, this.orderService, this, this);
    let okButton = ModalButtonGenerator.createOkButton(updateOrderitemTimeTask);
    let cancelButton = ModalButtonGenerator.createCancelButton();

    const dialogRef = this.dialog.open(ModalComponent, {
      data: {
        message: 'Auftrag wirklich löschen?',
        buttons: [okButton, cancelButton],
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      console.log('The dialog was closed');
    });
  }

  onEnterKeyPressed(event: any): void {
    event.preventDefault();
  }
}
