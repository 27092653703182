import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { OrderItem } from 'src/app/screens/order-item-overview/order-item-overview.component';
import { environment } from 'src/environments/environment';
import { OrderItemProcessEnum } from '../enums/orderitemprocess.enum';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root',
})
export class SocketioService {
  socket!: Socket;
  orderItems!: OrderItem[];
  public socketInitialzied = false;

  private orderItemsSubject: BehaviorSubject<OrderItem[]>;
  public orderItemsObs: Observable<OrderItem[]>;

  private loadingSubject: BehaviorSubject<boolean>;
  public loadingObs: Observable<boolean>;

  constructor(
    private http: HttpClient,
    private tokenStorage: TokenStorageService,
    private router: Router
  ) {
    this.orderItemsSubject = new BehaviorSubject<OrderItem[]>([]);
    this.orderItemsObs = this.orderItemsSubject.asObservable();

    this.loadingSubject = new BehaviorSubject<boolean>(true);
    this.loadingObs = this.loadingSubject.asObservable();
  }

  async setupSocketConnection() {
    const token = this.tokenStorage.getToken();
    this.initSocketConnection(token);
    this.socketInitialzied = true;
  }

  private initSocketConnection(vtoken: any) {
    this.socket = io(environment.SOCKET_ENDPOINT, {
      query: {
        token: vtoken,
      },
    });

    this.registerSocketListener();

    this.loadOrderItems();
  }

  registerSocketListener() {
    this.socket.on('connect', () => {
      console.log("Connected");
      this.loadOrderItems();
    });

    this.socket.on('orders:update', (data: OrderItem[]) => {
      var orderItems: OrderItem[] = data;
      orderItems.forEach((orderItem) => {
        orderItem.geplanterTermin = new Date(orderItem.geplanterTermin);
        orderItem.forecastEndDate = new Date(orderItem.forecastEndDate);
        var assignees: any[] = [];
        orderItem.assignees.forEach((employee: string[]) => {
          if (employee.length === 2) {
            var symbol = '';
            if (employee[0].length > 0) {
              symbol = employee[0].slice(0, 1);
            }
            if (employee[1].length > 0) {
              symbol = symbol + employee[1].slice(0, 1);
            }
            assignees.push(symbol);
          }
        });
        orderItem.assignees = assignees;
        //orderItem.arbeitszeit = this.formatTime(orderItem.arbeitszeit);
      });
      this.loadingSubject.next(false);
      this.orderItemsSubject.next(orderItems);
    });

    this.socket.on('user:update', (updatedUserTime: any) => {
      let user = this.tokenStorage.getUser();
      user['arbeitszeit'] = updatedUserTime.arbeitszeit;
      this.tokenStorage.saveUser(user);
    });

    this.socket.on('forcelogout', () => {
      this.tokenStorage.signOut();
      this.logout();
      this.router.navigate(['login']);
    })
  }

  updateOrderItem(process: OrderItemProcessEnum, orderItem: OrderItem, options: any) {
    this.socket.emit('order:update', { process, orderItem, ...options });
  }

  loadOrderItems() {
    this.orderItemsSubject.next([]);
    this.loadingSubject.next(false);
    this.socket.emit('orders:load', 'Hello ready for update.');
  }

  logout() {
    this.socket.disconnect();
  }

  formatTime(time: string): string {
    var timeSplitted = time.split('.');
    console.log('format time -> timeSplitted: ' + timeSplitted.toString());
    if (timeSplitted.length != 2) {
      return time;
    }
    var hours = timeSplitted[0];
    if (hours.length == 1) {
      hours = '0' + hours;
    }
    var minutesAsString = timeSplitted[1];
    var minutes: number = 0;

    try {
      if (minutesAsString.length == 1) {
        minutesAsString += '0';
      }
      minutes = parseInt(minutesAsString);
      minutes = Math.round((minutes / 100) * 60);

      minutesAsString = `${minutes}`;
      if (minutesAsString.length == 1) {
        minutesAsString = '0' + minutesAsString;
      }
    } catch (error) {
      console.log('error during time formatting');
    }

    var formattedTime = `${hours}:${minutesAsString}`;
    return formattedTime;
  }
}
