import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import packageInfo from '../../../../package.json';
import { SocketioService } from '../../services/socketio.service';
import { TokenStorageService } from '../../services/token-storage.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent implements OnInit {
  currentUser: any;
  isAdmin = false;
  version: string = packageInfo.version;
  showTimezonesBtn = environment.SHOW_TIMEZONES;
  showPwResetBtn = !environment.USE_LDAP;

  constructor(
    private tokenStorageService: TokenStorageService,
    private socketService: SocketioService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.tokenStorageService.currentUserObs.subscribe((user: any) => {
      this.currentUser = JSON.parse(user);
      this.isAdmin = this.currentUser.roles.includes('ROLE_ADMIN');
    });
  }

  logout() {
    this.tokenStorageService.signOut();
    this.socketService.logout();
    this.router.navigate(['login']);
  }
}
