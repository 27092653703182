<div class="features-boxed resultdetail">
  <div class="container mt-3">
    <div class="row">
      <div class="col">
        <button
          class="btn btn-custom btn-outline btnBack"
          type="button"
          [routerLink]="['/results']"
        >
          <i class="fa fa-chevron-left"></i>
          <span class="ml-1">Zeiten</span>
        </button>
      </div>
    </div>
  </div>
  <div class="info-section">
    <div class="info-section-container">
      <div class="info-header css-row">
        <h2>Korrektur</h2>
        <div class="info-header-options">
          <div class="order-no">
            <span>{{ "screens.correction.orderItemNo" | translate }}:</span>
            <span>{{ orderItemNo }}</span>
          </div>
        </div>
      </div>
      <div class="info-container">
        <div class="info-container-form">
          <div
            class="orderItemTimeContainer"
            *ngFor="let orderItemTime of orderItemTimes; let i = index"
          >
            <span class="oitTitle">Eintrag {{ i + 1 }}</span>
            <span *ngIf="!orderItemTime.userless" class="ml-1 text-secondary"
              >Faktor *{{ orderItemTime.timezonefactor }}</span
            >
            <span *ngIf="orderItemTime.userless" class="ml-1 text-secondary"
              >Maschinenlauf</span
            >
            <div class="oitStartContainer">
              <span>Start</span><span>zeit</span>
              <input
                class="form-control mb-1"
                type="date"
                name=""
                value="{{ orderItemTime.dateFrom }}"
                [disabled]="orderItemTime.disabled"
                #dateFrom
              />
              <input
                class="form-control"
                type="time"
                name=""
                value="{{ orderItemTime.timeFrom }}"
                [disabled]="orderItemTime.disabled"
                #timeFrom
              />
            </div>
            <div class="oitEndContainer">
              <span>Ende</span><span>zeit</span>
              <input
                class="form-control mb-1"
                type="date"
                name=""
                value="{{ orderItemTime.dateTo }}"
                [disabled]="orderItemTime.disabled"
                #dateTo
              />
              <input
                class="form-control"
                type="time"
                name=""
                value="{{ orderItemTime.timeTo }}"
                [disabled]="orderItemTime.disabled"
                #timeTo
              />
            </div>
            <button
              class="btn btn-custom"
              type="button"
              name="button"
              *ngIf="orderItemTime.disabled"
              (click)="enableOrderItemEdit(orderItemTime, true)"
            >
              <i class="fas fa-edit"></i>
              <span>&nbsp; Ändern</span>
            </button>
            <button
              class="btn btn-custom"
              type="button"
              name="button"
              *ngIf="!orderItemTime.disabled"
              (click)="
                saveChanges(orderItemTime, dateFrom, timeFrom, dateTo, timeTo)
              "
            >
              <i class="fas fa-cloud-download-alt"></i>
              <span>&nbsp; Speichern</span>
            </button>
            <button
              class="btn oitDeleteBtn"
              type="button"
              name="button"
              (click)="deleteOrderItemTime(orderItemTime)"
            >
              <i class="far fa-trash-alt"></i>
              <span>&nbsp;Löschen</span>
            </button>
          </div>
          <div
            *ngIf="orderItemTimes.length == 0 && !loading"
            class="p-5 text-center"
          >
            <i class="fa fa-clock-o mr-1"></i>
            Noch keine abgeschlossenen Zeiten erfasst.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
