import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-pw-reset',
  templateUrl: './pw-reset.component.html',
  styleUrls: ['./pw-reset.component.css']
})
export class PwResetComponent implements OnInit {

  form: any = {
    oldPW: null,
    newPW: null,
    newPWCheck: null
  }
  loading = false;
  savedSuccess = false;
  savedError = false;


  constructor(private userService: UserService) { }

  ngOnInit(): void {
  }

  onSubmit(f: NgForm): void {

    this.userService.setPassword(this.form.oldPW!, this.form.newPW!).subscribe(
      (data) => {
        this.loading = false;
        this.savedSuccess = true;
      },
      (err) => {
        this.loading = false;
        this.savedError = true;
      }
    )
  }

}
