<div class="features-boxed resultdetail">
  <div class="container mt-3">
    <div class="row">
      <div class="col-5 col-md-6">
        <button class="btn btn-custom btn-outline btnBack" type="button" [routerLink]="['/settings']">
          <i class="fa fa-chevron-left"></i>
          <span class="ml-1">Einstellungen</span>
        </button>
      </div>
      <div class="col-7 col-md-6">
        <button *ngIf="showAddUserBtn" class="btn btn-custom btn-outline btnBack right-btn" type="button" [routerLink]="['/userAdd']">
          <i class="fa"></i>
          <span class="ml-1">Nutzer hinzufügen</span>
        </button>
      </div>
    </div>
    <div class="intro small">
      <h3 class="text-center title">Nutzer</h3>
    </div>

    <div class="intro large">
      <h3 class="text-center title">Nutzer verwalten.</h3>
    </div>

    <div class="info-container orderItemsContainer ">
      <div class="container">
      <table class="user-overview-table">
        <tr>
          <th>Nutzername</th>
          <th>Nachname</th>
          <th>Vorname</th>
          <th>Rollen</th>
        </tr>
        <tr *ngFor="let user of users">
          <td>{{ user.username }}</td>
          <td>{{ user.lastName }}</td>
          <td>{{ user.firstName }}</td>
          <td>{{ user.roles }}</td>
        </tr>
      </table>
      <p class="user-overview-table-mobile-not-supported">
        <i class="fa fa-desktop"></i> Bitte größeres Anzeigegerät verwenden. Ansicht ist nicht für kleine Geräte freigeschaltet.
      </p>
      </div>
    </div>
