import { MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from 'src/app/modal/modal.component';
import { OrderItem } from 'src/app/screens/order-item-overview/order-item-overview.component';
import { OrderService } from 'src/app/services/order.service';
import { OnErrorTask } from 'src/app/tasks/on-error-task';
import { OnSuccessTask } from 'src/app/tasks/on-success-task';
import { Task } from '../task';

export class RemoveOrderitemTask implements Task {
  private orderitem: any | null = null;
  private orderService: OrderService | null = null;
  private delegateSuccess: OnSuccessTask | undefined;
  private delegateError: OnErrorTask | undefined;

  async execute(dialogRef?: MatDialogRef<ModalComponent>): Promise<void> {
    try {
      if (this.orderitem != null && this.orderService != null) {
        let result = await this.orderService
          .removeOrderItem(this.orderitem.id)
          .toPromise();

        dialogRef?.componentInstance?.enableTaskLoading(false);
        dialogRef?.close(true);

        this.delegateSuccess?.onSuccess(result);

        console.log('Executed ' + RemoveOrderitemTask.name);
        return;
      }

      this.handleError(
        'Failed ' +
          RemoveOrderitemTask.name +
          ' -> orderItem or orderService is null!',
        dialogRef
      );
    } catch (error) {
      this.handleError('Failed ' + RemoveOrderitemTask.name, dialogRef, error);
    }
  }

  handleError(
    clientMsg: string,
    dialogRef?: MatDialogRef<ModalComponent>,
    error?: any
  ) {
    dialogRef?.componentInstance?.enableTaskLoading(false);
    dialogRef?.close(true);

    this.delegateError?.onError(clientMsg);
    if (error) {
      console.error(error);
    }
  }

  setValues(
    orderItem: OrderItem,
    orderService: OrderService,
    delegateSuccess: OnSuccessTask,
    delegateError: OnErrorTask
  ) {
    this.orderitem = orderItem;
    this.orderService = orderService;
    this.delegateSuccess = delegateSuccess;
    this.delegateError = delegateError;
  }
}
