import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ButtonType } from '../enums/button-type.enum';
import { Task } from '../tasks/task';
import { ModalButton } from './buttons/modal-button';
import { HttpClient } from '@angular/common/http';
import { OrderService } from '../services/order.service';
import { DomSanitizer} from '@angular/platform-browser';

export interface ModalComponentData {
  message: string;
  buttons: ModalButton[];
  customMessage?: boolean;
  data: any;
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
})
export class ModalComponent implements OnInit {
  title: string = ''; // not in use
  message: string = '';
  buttons: ModalButton[] = [];
  SECONDARY_BTN_REF = ButtonType.SECONDARY;
  taskLoading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: ModalComponentData,
    protected orderService: OrderService,
    protected http: HttpClient,
    protected sanitizer: DomSanitizer,
    protected dialog: MatDialog,
  ) {
    if (data) {
      if (data.customMessage && data.customMessage == true){
        this.message = data.message
      }
      else {
        this.message = `<p>${data.message}<p>`;
      }
      if (data.buttons) {
        this.buttons = data.buttons;
      }
    }
  }

  executeTask(task: Task) {
    this.enableTaskLoading(true);
    task.execute(this.dialogRef);
  }

  ngOnInit(): void {
    console.log('openend dialog');
  }

  enableTaskLoading(loading: boolean) {
    this.taskLoading = loading;
  }
}
