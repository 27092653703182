import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CanActivateAuthGuard } from './helpers/can-activate-auth.guard';
// import { AddOrderComponent } from './screens/add-order/add-order.component';
import { CorrectionComponent } from './screens/correction/correction.component';
import { LoginComponent } from './screens/login/login.component';
import { MultiplierComponent } from './screens/multiplier/multiplier.component';
import { OrderItemDetailComponent } from './screens/order-item-detail/order-item-detail.component';
import { OrderItemHistoryOverviewComponent } from './screens/order-item-history-overview/order-item-history-overview.component';
import { OrderItemOverviewComponent } from './screens/order-item-overview/order-item-overview.component';
import { SettingsComponent } from './screens/settings/settings.component';
import { TimeTrackingEvaluationComponent } from './screens/time-tracking-evaluation/time-tracking-evaluation.component';
import { UserOverviewComponent } from './screens/user-overview/user-overview.component';
import { UserAddComponent } from './screens/user-add/user-add.component';
import { PwResetComponent } from './screens/pw-reset/pw-reset.component';
import { PdfViewComponent } from './screens/pdf-view/pdf-view.component';

//import { AuthGuard } from './helpers/auth.guard';

const routes: Routes = [
  { path: 'home', component: OrderItemOverviewComponent, canActivate: [CanActivateAuthGuard] },
  { path: 'login', component: LoginComponent },
  // { path: 'addOrder', component: AddOrderComponent, canActivate: [CanActivateAuthGuard] },
  { path: 'settings', component: SettingsComponent, canActivate: [CanActivateAuthGuard] },
  { path: 'results', component: OrderItemHistoryOverviewComponent, canActivate: [CanActivateAuthGuard] },
  { path: 'selfresults', component: TimeTrackingEvaluationComponent, canActivate: [CanActivateAuthGuard] },
  { path: 'multiplier', component: MultiplierComponent, canActivate: [CanActivateAuthGuard] },
  { path: 'editOrderitem/:id/:orderitemno', component: CorrectionComponent, canActivate: [CanActivateAuthGuard] },
  { path: 'detailOrderitem/:id/:orderitemno', component: OrderItemDetailComponent, canActivate: [CanActivateAuthGuard] },
  { path: 'userOverview', component: UserOverviewComponent, canActivate: [CanActivateAuthGuard]},
  { path: 'userAdd', component: UserAddComponent, canActivate: [CanActivateAuthGuard]},
  { path: 'pwReset', component: PwResetComponent, canActivate: [CanActivateAuthGuard]},


  // otherwise redirect to home
  { path: '', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
