import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogState, InfoDialogText } from 'src/app/enums/info-dialog.enum';
import { ModalButtonGenerator } from 'src/app/modal/buttons/impl/modal-button-generator';
import { ModalComponent } from 'src/app/modal/modal.component';
import { OrderItemRemarksModalComponent } from 'src/app/modal/orderitem-remarks-modal/orderitem-remarks-modal-component';
import { GeneralDate, OrderItem } from 'src/app/screens/order-item-overview/order-item-overview.component';
import { OrderitemService } from 'src/app/services/orderitem.service';
import { RunOrderItemUserlessTask } from 'src/app/tasks/impl/run-orderitem-userless.task';

export interface OrderItemEvent {
  event: string;
  orderItem: any;
}

@Component({
  selector: "app-orderitem-card",
  templateUrl: "./orderitem-card.component.html",
  styleUrls: ["./orderitem-card.component.css"],
})
export class OrderitemCardComponent implements OnInit {
  @Output()
  orderItemEvent = new EventEmitter<OrderItemEvent>();

  @Input()
  orderItem: any;

  @Input()
  genDate!: GeneralDate;

  infodialogbar: any = {
    state: InfoDialogState.DEFAULT,
    text: InfoDialogText.DEFAULT,
  };

  constructor(
    private orderItemService: OrderitemService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
  }

  isCritical(): boolean {
    this.orderItem.overtime =
      this.orderItem.forecastEndDate.getTime() - this.orderItem.geplanterTermin.getTime();
    const tolerance = -1 * 1000 * 60 * 60 * 24 * this.orderItem.warningBoundary;
    if (this.orderItem.overtime < tolerance) {
      return false;
    } else {
      return true;
    }
  }

  // in hours
  getOvertimeFormatted(orderItem: OrderItem) {
    return (orderItem.overtime / 1000 / 60 / 60 / 24).toFixed(0);
  }

  getWarningText(orderItem: OrderItem) {
    let overtime: string = this.getOvertimeFormatted(orderItem);
    let overtimeText = "";
    try {
      if (Number(overtime) >= 0) {
        overtimeText += "+";
      }
      overtimeText += overtime + " ";
      overtimeText += "Tag(e)";
    } catch (error) {
      overtimeText = "Fehler";
    }
    return overtimeText;
  }

  startOrderItem() {
    this.orderItemEvent.emit({
      event: "start",
      orderItem: this.orderItem,
    });
  }

  startOrderItemUserless() {
    this.orderItemEvent.emit({
      event: "startUserless",
      orderItem: this.orderItem,
    });
  }

  pauseOrderItemUserless() {
    this.orderItemEvent.emit({
      event: "pauseUserless",
      orderItem: this.orderItem,
    })
  }

  pauseOrderItem() {
    this.orderItemEvent.emit({
      event: "pause",
      orderItem: this.orderItem,
    });
  }

  stopOrderItem() {
    this.orderItemEvent.emit({
      event: "stop",
      orderItem: this.orderItem,
    });
  }

  checkOrderItem() {
    this.orderItemEvent.emit({
      event: "check",
      orderItem: this.orderItem,
    });
  }

  openRunUserlessDialog() {
    if (this.orderItem.bearbeitungszustand == "300") {
      let runUserLessTask = new RunOrderItemUserlessTask();
      runUserLessTask.setValues(
        this.orderItem,
        this.orderItemService,
        this.infodialogbar
      );
      let okButton = ModalButtonGenerator.createOkButton(runUserLessTask);
      let cancelButton = ModalButtonGenerator.createCancelButton();

      const dialogRef = this.dialog.open(ModalComponent, {
        data: {
          message: "Auftrag mannlos starten?",
          buttons: [okButton, cancelButton],
        },
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        console.log("The dialog was closed");
      });
    }
  }

  openRemarksDialog() {
    let cancelButton = ModalButtonGenerator.createCancelButton();

    const dialogRef = this.dialog.open(OrderItemRemarksModalComponent, {
      data: {
        customMessage: true,
        message: this.orderItem.remarks,
        buttons: [cancelButton],
        data: {orderItemId: this.orderItem.id, pdfLink: this.orderItem.pdfLink},
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      console.log("The dialog was closed");
    });
  }
}
